import React from 'react';
import SettingsBox from './CompensationSettingsBox';
import FrequencyDataBox from './CompensationFrequencyBox';
import SettingsNavbar from '../../Settingspage/SettingsNavbar'
import ButtonUtil from '../../../Global/ButtonUtil'; // Reusable button utility component
import { useNavigate } from 'react-router-dom'; // For programmatic navigation
import { HeadPhoneIconv1, HomeIcon, SpeakersIcon, TestIconv1 } from '../../../../assets/Icons'; // Icons for buttons

const AudiogramHeadphoneCompensation = () => {
    const navigate = useNavigate(); // Hook to navigate between routes
  
    return (
      <div className="min-h-screen bg-gray-100">
        {/* Navbar for navigation */}
        <SettingsNavbar>
          <div className="flex gap-4">
            <ButtonUtil icon={HomeIcon} handleClick={() => navigate('/home')} text="Home" />
            <ButtonUtil icon={TestIconv1} handleClick={() => navigate('/Settings')} text="Takaisin asetuksiin" />
            <ButtonUtil icon={TestIconv1} handleClick={() => navigate('/AudiogramCalibration')} text='Takaisin kalibrointiruutuun' />
          </div>
        </SettingsNavbar>
  
        {/* Main Content Layout */}
        <div className="pt-10 px-4">
            <div className="grid grid-cols-2 gap-5 p-6">
            <div className="left-section flex flex-col">
                <SettingsBox />

            </div>
            <div className="right-section flex flex-col">
                <FrequencyDataBox />
            </div>
            </div>
        </div>
      </div>
    );
  };
  
  export default AudiogramHeadphoneCompensation;