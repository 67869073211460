import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "../Slices/authSlice";
import { soundSlice } from "../Slices/soundSilce";
import { dinSlice } from "../Slices/dinSettingSlice";


/* Configure store for global state management */

export const store = configureStore({
  reducer: {
    authState: authSlice.reducer,
    soundState: soundSlice.reducer,
    dinState: dinSlice.reducer
  },
});