/* List files  */
const lists = {
    list1: ['001-001', '001-002', '001-003', '001-004', '001-005', '001-006', '001-007', '001-008', '001-009', '001-010', '001-011', '001-012', '001-013', '001-014', '001-015', '001-016', '001-017', '001-018', '001-019', '001-020'],
    list2: ['002-001', '002-002', '002-003', '002-004', '002-005', '002-006', '002-007', '002-008', '002-009', '002-010', '002-011', '002-012', '002-013', '002-014', '002-015', '002-016', '002-017', '002-018', '002-019', '002-020'],
    list3: ['003-001', '003-002', '003-003', '003-004', '003-005', '003-006', '003-007', '003-008', '003-009', '003-010', '003-011', '003-012', '003-013', '003-014', '003-015', '003-016', '003-017', '003-018', '003-019', '003-020'],
    list4: ['004-001', '004-002', '004-003', '004-004', '004-005', '004-006', '004-007', '004-008', '004-009', '004-010', '004-011', '004-012', '004-013', '004-014', '004-015', '004-016', '004-017', '004-018', '004-019', '004-020'],
    list5: ['005-001', '005-002', '005-003', '005-004', '005-005', '005-006', '005-007', '005-008', '005-009', '005-010', '005-011', '005-012', '005-013', '005-014', '005-015', '005-016', '005-017', '005-018', '005-019', '005-020'],
    list6: ['006-001', '006-002', '006-003', '006-004', '006-005', '006-006', '006-007', '006-008', '006-009', '006-010', '006-011', '006-012', '006-013', '006-014', '006-015', '006-016', '006-017', '006-018', '006-019', '006-020'],
    list7: ['007-001', '007-002', '007-003', '007-004', '007-005', '007-006', '007-007', '007-008', '007-009', '007-010', '007-011', '007-012', '007-013', '007-014', '007-015', '007-016', '007-017', '007-018', '007-019', '007-020'],
    list8: ['008-001', '008-002', '008-003', '008-004', '008-005', '008-006', '008-007', '008-008', '008-009', '008-010', '008-011', '008-012', '008-013', '008-014', '008-015', '008-016', '008-017', '008-018', '008-019', '008-020'],
    list9: ['009-001', '009-002', '009-003', '009-004', '009-005', '009-006', '009-007', '009-008', '009-009', '009-010', '009-011', '009-012', '009-013', '009-014', '009-015', '009-016', '009-017', '009-018', '009-019', '009-020']
    // Add more lists as needed
};

/* Answers for corresponding list */
const answers = {
    lista1: ['254', '529', '831', '368', '185', '492', '715', '908', '640', '793', '826', '072', '157', '206', '413', '569', '674', '347', '930', '081'],
    lista2: ['521', '610', '169', '245', '057', '738', '371', '896', '482', '514', '906', '148', '062', '693', '239', '807', '453', '924', '780', '375'],
    lista3: ["710", "486", "901", "028", "215", "172", "805", "579", "391", "936", "653", "280", "723", "469", "147", "632", "894", "059", "348", "567"],
    lista4: ["362", "159", "691", "910", "437", "073", "270", "725", "812", "643", "586", "989", "341", "124", "734", "258", "095", "408", "567", "806"],
    lista5: ["436", "845", "253", "708", "361", "619", "957", "213", "024", "892", "378", "541", "462", "970", "629", "784", "580", "196", "037", "105"],
    lista6: ["846", "315", "768", "461", "729", "570", "052", "217", "903", "141", "057", "630", "592", "376", "829", "493", "904", "685", "184", "238"],
    lista7: ["167", "256", "042", "615", "931", "349", "053", "628", "597", "920", "764", "438", "809", "510", "385", "173", "786", "294", "801", "472"],
    lista8: ["879", "967", "490", "278", "561", "748", "626", "386", "154", "087", "425", "309", "635", "092", "531", "843", "102", "713", "250", "914"],
    lista9: ["084", "736", "398", "576", "627", "902", "459", "165", "201", "817", "162", "735", "249", "648", "051", "913", "374", "590", "480", "823"]
};

/* Export the data */
export { lists, answers };

