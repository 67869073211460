import { createSlice } from "@reduxjs/toolkit";
/* Making the soundTest Variable globlally accessible */
export const soundSlice = createSlice({
    name: "soundtest",
    initialState: {
        soundTest: [
            { name: 'Kuulokkeiden sovitus', duration: '2 min', navigate: '/Kuulokkeiden', completed: false },
            { name: 'Hälypuhetesti', duration: '20 min', navigate: '/HalyPuheTesti', completed: false },
            { name: 'Hälypuhetestin kysely', duration: '3 min', navigate: '/QuestionnaireDIN', completed: false },
            { name: 'Kuulokysely', duration: '5 min', navigate: '/KuuloKyselyLandingPage', completed: false },
            { name: 'Kuulokyselyn kysely', duration: '3 min', navigate: '/QuestionnairePROM', completed: false },
            { name: `Kuulokynnys`, duration: '30 min', navigate: '/AudiogramLandingPage', completed: false },
            { name: 'Kuulokynnyksen kysely', duration: '3 min', navigate: '/QuestionnaireAUD', completed: false },
            { name: 'Loppukysely', duration: '5 min', navigate: '/QuestionnaireFINISH', completed: false },
           // { name: 'Smart Audiogram', duration: '20min', navigate: '/TestLandingPage', completed: false },
        ]

        ,soundTestIndex: null,

    },
    reducers: {
        setSoundTest: (state, action) => {
            state.soundTest = action.payload;
        },
        setSoundTestIndex: (state, action) => {
            state.soundTestIndex = action.payload;
        },
        /* Testing by completing it */
        completeRandomTest: (state) => {
            const incompleteTests = state.soundTest.filter(test => !test.completed);
            if (incompleteTests.length > 0) {
                const randomIndex = Math.floor(Math.random() * incompleteTests.length);
                const testToComplete = incompleteTests[randomIndex];
                const indexToUpdate = state.soundTest.findIndex(test => test.name === testToComplete.name);
                if (indexToUpdate !== -1) {
                    state.soundTest[indexToUpdate].completed = true;
                }
            }
        }
    },
});

// Action creators are generated for each case reducer function
export const { setSoundTest, setSoundTestIndex } = soundSlice.actions;