import React, { useEffect, useRef, useState } from 'react';
import ButtonUtil from '../../../Global/ButtonUtil'; // Utility button component
import { HeadPhoneIconv1, LeftIcon, PauseIcon, RightIcon, SettingsIcon } from '../../../../assets/Icons'; // Icons used in the UI
import { useLocation, useNavigate } from 'react-router-dom'; // Router hooks for handling navigation and URL params
import { useDispatch } from 'react-redux'; // Redux hooks for state management
import { setHeadPhoneLeftEarSetting, setHeadPhoneRightEarSetting, setSpeakerLeftEarSetting, setSpeakerRightEarSetting } from '../../../../Slices/dinSettingSlice'; // Redux actions
import { toast, ToastContainer } from 'react-toastify'; // Toast notification library
import SettingsNavbar from '../../Settingspage/SettingsNavbar'; // Settings navigation bar component
import isElectron from '../../../../Functions/isElectron';

const SpeakerCalibrationPage = () => {
    // const { type } = useParams(); // Retrieves 'type' param from URL (e.g., 'headPhone' or 'speaker')
    const type = 'speaker';
    const location = useLocation(); // Access the current location and its state
    const soundSource = "speaker"; // Optional state from previous page
    const [defaultSettings, setDefaultSettings] = useState(location.state?.defaultSettings || {}); // Default calibration settings
    console.log(defaultSettings);
    const defaultDB = 65.00; // Default decibel level for noise
    const [leftRange, setLeftRange] = useState(0); // State for left ear decibel range
    const [rightRange, setRightRange] = useState(0); // State for right ear decibel range

    const [currentChannel, setCurrentChannel] = useState(null); // Tracks which sound channel is currently playing (left, right, or both)
    const dispatch = useDispatch(); // Redux dispatch to update settings
    const navigate = useNavigate(); // For navigation between pages
    console.log({ 'speakercalibrationIN': 'IN' });
    // Select appropriate ear settings from the redux store based on the 'type'
    // const leftEarSetting = useSelector((state) => type === 'headPhone' ? state.dinState.headPhoneLeftEarSetting : state.dinState.speakerLeftEarSetting);
    // const rightEarSetting = useSelector((state) => type === 'headPhone' ? state.dinState.headPhoneRightEarSetting : state.dinState.speakerRightEarSetting);

    // References for managing the Web Audio API elements
    const audioContextRef = useRef(null); // Audio context for sound manipulation
    const noiseSourceRef = useRef(null); // Reference to the sound source
    const noiseBufferRef = useRef(null); // Buffer for storing the sound data
    const leftGainNodeRef = useRef(null); // Gain node for controlling left ear volume
    const rightGainNodeRef = useRef(null); // Gain node for controlling right ear volume

    const noise550ms = require('../../../../assets/Sounds/noise550ms.wav'); // Import the noise sound file



    // Function to play noise sound on a specific channel (left, right, or both)
    const playNoise = (channel = 'both') => {
        pauseNoise(); // Pause any current noise before playing a new one
        const leftDecibels = defaultDB + leftRange; // Calculate left ear decibel level
        const rightDecibels = defaultDB + rightRange; // Calculate right ear decibel level

        if (noiseBufferRef.current) {
            noiseSourceRef.current = audioContextRef.current.createBufferSource();
            noiseSourceRef.current.buffer = noiseBufferRef.current;
            noiseSourceRef.current.loop = true;

            const splitter = audioContextRef.current.createChannelSplitter(2); // Split channels (left and right)
            const merger = audioContextRef.current.createChannelMerger(2); // Merge channels back together

            noiseSourceRef.current.connect(splitter);

            // Configure the gain node for left ear if channel is 'left' or 'both'
            if (channel === 'left' || channel === 'both') {
                splitter.connect(leftGainNodeRef.current, 0);
                leftGainNodeRef.current.connect(merger, 0, 0);
                leftGainNodeRef.current.gain.value = calculateGainFromDecibels(leftDecibels); // Set gain for left ear
            }

            // Configure the gain node for right ear if channel is 'right' or 'both'
            if (channel === 'right' || channel === 'both') {
                splitter.connect(rightGainNodeRef.current, 1);
                rightGainNodeRef.current.connect(merger, 0, 1);
                rightGainNodeRef.current.gain.value = calculateGainFromDecibels(rightDecibels); // Set gain for right ear
            }

            merger.connect(audioContextRef.current.destination); // Send merged audio to speakers
            noiseSourceRef.current.start(0); // Start playing the noise
            setCurrentChannel(channel); // Update the currently playing channel
        }
    };

    // Function to pause the noise sound
    const pauseNoise = () => {
        try {
            if (noiseSourceRef.current) {
                noiseSourceRef.current.stop();
                noiseSourceRef.current = null;
                console.log('Background noise paused');
            }
            // Reset gain nodes by setting their values to 0 or disconnecting them
            if (leftGainNodeRef.current) {
                leftGainNodeRef.current.gain.value = 0;
                console.log(`[+] Resetting LeftNoiseGainNode`);
                leftGainNodeRef.current.disconnect();
            }
            if (rightGainNodeRef.current) {
                rightGainNodeRef.current.gain.value = 0;
                console.log(`[+] Resetting RightNoiseGainNode`);
                rightGainNodeRef.current.disconnect();
            }
        } catch (error) {
            console.log(error.message);
        }
    };
    // Helper function to calculate gain value from decibels
    const calculateGainFromDecibels = (decibels) => Math.pow(10, (decibels - 80) / 20);

    // Event handler for changing the left ear range value
    const handleLeftChange = (e) => {
        const value = e.target.value;

        if (value === '' || isNaN(value)) {
            setLeftRange(''); // Temporarily set the input to empty without updating gain
            return;
        }
        setLeftRange(parseFloat(e.target.value)); // Update left range state
        if (currentChannel === 'left' || currentChannel === 'both') {
            leftGainNodeRef.current.gain.value = calculateGainFromDecibels(defaultDB + parseFloat(e.target.value)); // Adjust left ear gain if it's currently playing
        }
    };

    // Event handler for changing the right ear range value
    const handleRightChange = (e) => {
        const value = e.target.value;
        if (value === '' || isNaN(value)) {
            setRightRange(''); // Temporarily set the input to empty without updating gain
            return;
        }
        setRightRange(parseFloat(e.target.value)); // Update right range state
        if (currentChannel === 'right' || currentChannel === 'both') {
            rightGainNodeRef.current.gain.value = calculateGainFromDecibels(defaultDB + parseFloat(e.target.value)); // Adjust right ear gain if it's currently playing
        }
    };

    // Function to update the Redux store with new ear settings
    const updateSettingEarRanges = async () => {
        try {
            let updatedDefaultData = {};
            const leftRangeValue = parseFloat(leftRange); // Get current left ear range value
            const rightRangeValue = parseFloat(rightRange); // Get current right ear range value

            const isHeadPhone = type === 'headPhone'; // Check if we are dealing with headphones or speakers

            // Update the Redux store with the new settings
            if (isHeadPhone) {
                dispatch(setHeadPhoneLeftEarSetting(leftRangeValue));
                dispatch(setHeadPhoneRightEarSetting(rightRangeValue));
            } else {
                dispatch(setSpeakerLeftEarSetting(leftRangeValue));
                dispatch(setSpeakerRightEarSetting(rightRangeValue));
            }

            // Update calibration data based on soundSource and type
            const earSettings = isHeadPhone
                ? { leftSetting: 'headPhoneLeftEarSetting', rightSetting: 'headPhoneRightEarSetting' }
                : { leftSetting: 'speakerLeftEarSetting', rightSetting: 'speakerRightEarSetting' };

            if (soundSource === type) {
                updatedDefaultData = {
                    ...defaultSettings,
                    [earSettings.leftSetting]: leftRangeValue,
                    [earSettings.rightSetting]: rightRangeValue,
                    leftEarCalibration: leftRangeValue,
                    rightEarCalibration: rightRangeValue
                };
            } else {
                updatedDefaultData = {
                    ...defaultSettings,
                    [earSettings.leftSetting]: leftRangeValue,
                    [earSettings.rightSetting]: rightRangeValue
                };
            }

            setDefaultSettings(updatedDefaultData); // Update state with new settings
            if (isElectron() && window.electron.isElectron && window.electron.savedinSettings) {
                try {
                    // Save the settings to the local file in Electron
                    const response = await window.electron.savedinSettings(updatedDefaultData);
                    if (response.success) {
                        toast.success('Settings saved to file!');
                    } else {
                        toast.error(response.message);
                    }
                } catch (error) {
                    console.log(error.message);
                    return toast.error('Error saving settings to file.');
                }
            }
            else
                toast.info('[+] Skipping saving as we are in browser environment');
            toast.success('Settings Updated'); // Show success notification
        } catch (error) {
            console.error(`[+] Something went wrong in updateSettingEarRanges:`, error.message);
            toast.error('Something went wrong in updateSettingEarRanges'); // Show error notification
        }
    };

    useEffect(() => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

        // Create gain nodes for the noise
        leftGainNodeRef.current = audioContextRef.current.createGain();
        rightGainNodeRef.current = audioContextRef.current.createGain();


        // Load and decode the background noise audio
        fetch(noise550ms)
            .then(response => response.arrayBuffer())
            .then(buffer => audioContextRef.current.decodeAudioData(buffer))
            .then(decodedBuffer => {
                noiseBufferRef.current = decodedBuffer;
                console.log('Background noise loaded and decoded');
            });

        //


        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []); // Reload when selectedList changes

    return (
        <div className='min-h-screen bg-gray-100 flex flex-col p-4'>
            {/* Settings navigation bar */}
            <SettingsNavbar>
                <ButtonUtil icon={SettingsIcon} handleClick={() => navigate('/settings')} text='Settings' />
            </SettingsNavbar>

            {/* Main content */}
            <div className='px-6'>
                <p className='text-[#344054] text-2xl font-bold'>
                    {type === 'headPhone' ? 'Kalibroi kuulokkeet' : 'Kalibroi kaiuttimet'} kohinan avulla 65dB tavoitevahvistukseen.
                </p>

                {/* Noise control buttons */}
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-3 pt-4'>
                    <div className='flex gap-x-1 bg-white px-4 py-2 rounded-md justify-center items-center hover:bg-gray-400 cursor-pointer' onClick={pauseNoise}>
                        <img src={PauseIcon} className='size-4' alt='Pause Icon' />
                        <button>Pysayta Kohina</button>
                    </div>
                    <div className='flex gap-x-1 bg-white px-4 py-2 rounded-md justify-center items-center hover:bg-gray-400 cursor-pointer' onClick={() => playNoise('left')}>
                        <img src={LeftIcon} className='size-4' alt='Left Icon' />
                        <button>Soita Kohina Vasemmalle</button>
                    </div>
                    <div className='flex gap-x-1 bg-white px-4 py-2 rounded-md justify-center items-center hover:bg-gray-400 cursor-pointer' onClick={() => playNoise('right')}>
                        <img src={RightIcon} className='size-4' alt='Right Icon' />
                        <button>Soita Kohina Oikealle</button>
                    </div>
                    <div className='flex gap-x-1 bg-white px-4 py-2 rounded-md justify-center items-center hover:bg-gray-400 cursor-pointer' onClick={() => playNoise('both')}>
                        <img src={HeadPhoneIconv1} className='size-4' alt='Headphone Icon' />
                        <button>Soita Kohina Molemmille</button>
                    </div>
                </div>

                {/* Left ear volume control */}
                <div className='mt-14'>
                    <label className='text-[#344054] font-semibold'>Vasemman Kanavan Vahvistus</label>
                    <div className='flex items-center gap-4 mt-2'>
                        <input type='range' min='-100' max='20' step='0.1' value={leftRange} onChange={handleLeftChange} className='w-full md:w-3/4' />
                        <input type='number' min='-100' max='20' step='0.1' value={leftRange} onChange={handleLeftChange} className='w-full md:w-1/4 p-2 border rounded-md' />
                    </div>
                </div>

                {/* Right ear volume control */}
                <div className='mt-6'>
                    <label className='text-[#344054] font-semibold'>Oikean Kanavan Vahvistus</label>
                    <div className='flex items-center gap-4 mt-2'>
                        <input type='range' min='-100' max='20' step='0.1' value={rightRange} onChange={handleRightChange} className='w-full md:w-3/4' />
                        <input type='number' min='-100' max='20' step='0.1' value={rightRange} onChange={handleRightChange} className='w-full md:w-1/4 p-2 border rounded-md' />
                    </div>
                </div>

                {/* Save calibration button */}
                <div className='mt-4' onClick={updateSettingEarRanges}>
                    <button className='bg-purple-500 text-white px-4 py-2 hover:bg-purple-900 rounded-md'>
                        Tallenna Kalibrointi
                    </button>
                </div>
            </div>

            <ToastContainer /> {/* Toast notifications */}
        </div>
    );
};

export default SpeakerCalibrationPage;
