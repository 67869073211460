// updateSoundTestStatus.js
export const updateSoundTestStatus = (soundTest, soundTestIndex, dispatch, setSoundTest, updatevalue = true) => {
    try {
        // Check if soundTest and soundTestIndex are valid before proceeding
        if (!Array.isArray(soundTest) || soundTestIndex < 0 || soundTestIndex >= soundTest.length) {
            throw new Error("Invalid soundTest array or soundTestIndex");
        }
        console.log('[+] updateSoundTest:', soundTestIndex);
        // Create a new array and update the specific test item
        const updatedSoundTest = soundTest.map((test, index) =>
            index === soundTestIndex
                ? { ...test, completed: updatevalue }  // Create a new object with updated 'completed' flag
                : test
        );
        console.log({ updatedSoundTest });
        // Dispatch the updated soundTest array to Redux
        return dispatch(setSoundTest(updatedSoundTest));
    } catch (error) {
        console.error("Error updating soundTest status:", error);
        throw new Error("Failed to update sound test status");
    }
};

/*  */
export const updateSoundTestStatusALL = (soundTest, dispatch, setSoundTest, updatevalue = false) => {
    try {
        // Ensure soundTest is an array
        if (!Array.isArray(soundTest)) {
            throw new Error("Invalid soundTest array");
        }

        // Update all items in the soundTest array
        const updatedSoundTest = soundTest.map(test => ({
            ...test,
            completed: updatevalue,  // Set all 'completed' flags to the updatevalue (false for logout)
        }));

        console.log({ updatedSoundTest });

        // Dispatch the updated soundTest array to Redux
        dispatch(setSoundTest(updatedSoundTest));
    } catch (error) {
        console.error("Error updating soundTest status:", error);
        throw new Error("Failed to update sound test status");
    }
};

export const updateTestStatusByName = (soundTest, testName, dispatch, setSoundTest, updatevalue = true) => {
    try {
      // Check if soundTest is a valid array
      if (!Array.isArray(soundTest)) {
        throw new Error("Invalid soundTest array");
      }
      console.log('[+] updateSoundTest:', testName);
  
      // Check if test with the given name exists
      const testExists = soundTest.some(test => test.name === testName);
  
      if (!testExists) {
        throw new Error(`Test with name "${testName}" not found`);
      }
  
      // Create a new array and update the specific test item
      const updatedSoundTest = soundTest.map((test) =>
        test.name.toLowerCase() === testName.toLowerCase()
          ? { ...test, completed: updatevalue }  // Update 'completed' flag
          : test
      );
  
      console.log({ updatedSoundTest });
      // Dispatch the updated soundTest array to Redux
      return dispatch(setSoundTest(updatedSoundTest));
    } catch (error) {
      console.error("Error updating soundTest status:", error);
      throw new Error("Failed to update sound test status");
    }
  };
  
