import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOutIconv1 } from '../../../assets/Icons';
import ButtonUtil from '../../Global/ButtonUtil';

const SettingsNavbar = ({ children }) => {
    const navigate = useNavigate();


    const handle_Logout = (e) => {
        if (localStorage.getItem('user'))
            localStorage.removeItem('user');
        if (localStorage.getItem('token'))
            localStorage.removeItem('token');
        return navigate('/');
    };


    return (
        <nav className='w-full cursor-pointer px-0'>
            <div className='p-4 flex items-center justify-between'>
                <div className='flex gap-x-2'>
                    <ButtonUtil icon={LogOutIconv1} handleClick={handle_Logout} text='Log Out' />
                    {children}
                </div>
            </div>
        </nav>
    );
};

export default SettingsNavbar;