import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AbortIcon } from '../../assets/Icons'; // Assuming Icons are imported correctly
import './Modal2.css';

const AbortPage = ({ onClose, resumeTest }) => {
  const navigate = useNavigate();
  const EnableContinueButtonRef = useRef(false); // Initially set to false
  const EnableAbortButtonRef = useRef(false); // Same for the "Keskeytä" button
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // State for rerender

  useEffect(() => {
    // Simulate enabling the buttons after a condition is met (e.g., after 3 seconds)
    const timeout = setTimeout(() => {
      EnableContinueButtonRef.current = true;
      EnableAbortButtonRef.current = true; // Enable "Keskeytä" button too
      setIsButtonEnabled(true); // Trigger rerender
    }, 4000); // Example: Enable the buttons after 3 seconds

    return () => clearTimeout(timeout); // Cleanup the timeout on unmount
  }, []);

  const handleContinue = () => {
    if (EnableContinueButtonRef.current) {
      console.log('Continuing the test');
      resumeTest(); // Call resumeTest to resume the test
      onClose(); // Close the modal
    }
  };

  const handleAbort = () => {
    if (EnableAbortButtonRef.current) {
      console.log('Aborting test');
      navigate('/TestLandingPage'); // Redirect to the landing page after abort
    }
  };

  return (
    <div className="sign-out-modal-overlay">
      <div className="sign-out-modal modal-content">
        {/* Abort Icon Display */}
        <div className="flex justify-left mb-4">
          <img src={AbortIcon} alt="Abort Icon" className="w-16 h-16" />
        </div>
        <p className="sign-out-modal-text font-bold text-left">Keskeytä testi</p>
        <p className="sign-out-modal-text2 text-[#101828] text-2xl text-left">
          Oletko varma, että haluat keskeyttää testin?
        </p>
        <div className="sign-out-modal-actions flex justify-between gap-x-6 mt-2">
          <button
            onClick={handleContinue}
            disabled={!EnableContinueButtonRef.current} // Disable until button is enabled
            className={`flex justify-center items-center border border-gray-300 py-3 px-2 rounded-lg transition-colors duration-300 ease-in-out gap-x-1 flex-1 ${
              EnableContinueButtonRef.current
                ? 'bg-white text-gray-500 hover:bg-gray-300 cursor-pointer' // Normal enabled button styling
                : 'bg-gray-200 text-gray-400 cursor-not-allowed' // Grayed-out disabled button styling
            }`}
          >
            Palaa testiin
          </button>
          <button
            onClick={handleAbort}
            disabled={!EnableAbortButtonRef.current} // Disable until button is enabled
            className={`flex justify-center items-center border border-gray-300 py-3 px-3 rounded-lg transition-colors duration-300 ease-in-out gap-x-1 flex-1 ${
              EnableAbortButtonRef.current
                ? 'bg-red-600 text-gray-100 hover:bg-red-800 cursor-pointer' // Red when enabled
                : 'bg-gray-200 text-gray-400 cursor-not-allowed' // Grayed-out when disabled
            }`}
          >
            Keskeytä
          </button>
        </div>
      </div>
    </div>
  );
};

export default AbortPage;
