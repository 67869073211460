import React, { useState, useEffect } from "react";
//import { AbortIcon, CornerDownRightIcon, CrossIcon, InfoIcon, PauseIcon, ReplayIcon, ResumeIcon, SoundIcon } from '../../../assets/Icons'; // Assuming SoundIcon is imported correctly
import QuestionTitle from "./QuestionTitle";
import RatingScale from "./RatingScale";
import NavigationBar from "./NavigationBar";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import isValidJSON from "../../../Functions/IsValidJSON";

const questions = [
  {id: 1, text: "Kuinka paljon jouduit ponnistelemaan edellisessä testissä." },
  {id: 2, text: "Millaiseksi koette kuulonne?" },
  {id: 3, text: "Kun keskustelen kotona kahdestaan toisen henkilön kanssa, minun on vaikea kuulla hänen puhettaan."},
  {id: 4, text: "Minun täytyy pyytää keskustelukumppaniani toistamaan sanomaansa keskustellessamme kahden hiljaisessa huoneessa."},
  {id: 5, text: "Minun on vaikea saada selvää keskustelukumppanini puheesta tilanteissa, joissa muut ihmiset samanaikaisesti keskustelevat ympärillämme."},
  {id: 6, text: "Minun on vaikea saada selvää esiintyjien puheesta teatterissa, konsertissa tai muussa vastaavassa tilanteessa."},
  {id: 7, text: "Minun on vaikea saada selvää puheesta paikoissa, joissa ääni kaikuu (esim. kirkossa papin saarna)."},
  {id: 8, text: "Minun täytyy pinnistellä saadakseni selvää, kun kuuntelen jotakin tai jotakuta."},
  {id: 9, text: "Pystyn arvioimaan pelkän äänen perusteella, mistä suunnasta ääni kuuluu (esim. koiran haukunta ulkona tai auton ääni)."},
  {id: 10, text: "Pystyn arvioimaan pelkän äänen perusteella, miltä etäisyydeltä ääni kuuluu (esim. koiran haukunta tai auton ääni)."},
  {id: 11, text: "Pystyn arvioimaan pelkän äänen perusteella, onko äänilähde (esim. haukkuva koira tai auto) tulossa minua kohti vai menossa minusta poispäin.",},
  {id: 12, text: "Tarvitsen muiden apua kuulovaikeuksieni vuoksi." },
  {id: 13, text: "Kuulovaikeuteni haittaavat sosiaalista elämääni (esim. ystävien/tuttavien tapaamista, osallistumista harrastuksiin tai eri tilaisuuksiin)." },
  {id: 14, text: "Koen jääväni kuulovaikeuksien vuoksi ulkopuoliseksi ryhmässä."},
  {id: 15, text: "Turhaudun kuulovaikeuksieni vuoksi." },
  {id: 16, text: "Nolostun kuulovaikeuksieni vuoksi." },
];

const answerDescriptions = {
  1: { 1: "Erittäin vaikeaa", 10: "Ei lainkaan vaikeaa" },
  2: { 1: "Erittäin huonoksi", 10: "Erittäin hyväksi" },
  3: { 1: "Erittäin vaikeaa", 10: "Ei lainkaan vaikeaa" },
  4: { 1: "Jatkuvasti", 10: "Ei koskaan" },
  5: { 1: "Erittäin vaikeaa", 10: "Ei lainkaan vaikeaa" },
  6: { 1: "Erittäin vaikeaa", 10: "Ei lainkaan vaikeaa" },
  7: { 1: "Erittäin vaikeaa", 10: "Ei lainkaan vaikeaa" },
  8: { 1: "Jatkuvasti", 10: "Ei koskaan" },
  9: { 1: "En pysty lainkaan", 10: "Pystyn helposti" },
  10: { 1: "En pysty lainkaan", 10: "Pystyn helposti" },
  11: { 1: "En pysty lainkaan", 10: "Pystyn helposti" },
  12: { 1: "Jatkuvasti", 10: "Ei koskaan" },
  13: { 1: "Erittäin paljon", 10: "Ei lainkaan" },
  14: { 1: "Jatkuvasti", 10: "En koskaan" },
  15: { 1: "Jatkuvasti", 10: "En koskaan" },
  16: { 1: "Jatkuvasti", 10: "En koskaan" },
};
function KuuloKysely() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedRating, setSelectedRating] = useState(null); // Track selected rating
  const [answers, setAnswers] = useState([]); // Store answers
  const [testStartTimestamp, setTestStartTimestamp] = useState(null); // Store test start time
  // const answersRef = useRef([])

  const tokenFromStorage = localStorage.getItem("token");
  const token =
    useSelector((state) => state.authState.token) ||
    (tokenFromStorage && isValidJSON(tokenFromStorage)
      ? JSON.parse(tokenFromStorage)
      : null);

  // Set the test start time when the component is first mounted
  useEffect(() => {
    setTestStartTimestamp(new Date()); // Set test start time when the component mounts
  }, []);

  const nextQuestion = () => {
    if (selectedRating) {
      // Store the answer
      const updatedAnswers = [
        ...answers,
        {
          questionID: questions[currentQuestionIndex].id,
          answer: selectedRating,
        },
      ];
      setAnswers(updatedAnswers);
      //answersRef.current = updatedAnswers

      if (currentQuestionIndex < questions.length - 1) {
        // Move to the next question
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedRating(null); // Reset rating for the next question
      }
    } else {
      toast.warning("Valitse .");
    }
  };

  // Submit all answers to the backend once all 15 questions are answered
  const submitAnswers = async (answers) => {
    const testEndTimestamp = new Date(); // Set the test end time when submitting
    const payload = {
      testStartTimestamp: testStartTimestamp, // Adjust as per your logic
      testEndTimestamp: testEndTimestamp, // Adjust as per your logic
      questionHistory: answers,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/questionnaire",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      if (response.ok) {
        console.log("Answers submitted successfully");
        toast.success("Answers submitted successfully!");
      } else {
        throw new Error("Failed to submit answers");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
      toast.error("Error submitting answers. Please try again.");
    }
  };

  const handleRatingSelect = (rating) => {
    setSelectedRating(rating);
  };

  const currentQuestion = questions[currentQuestionIndex];


  return (
    //<main className="flex overflow-hidden flex-col items-center px-4 pt-4 pb-12 bg-gray-100">
    //  <section className="flex flex-col items-center mt-[468px] max-md:mt-10 max-md:max-w-full">
    <main className="h-screen bg-gray-100 overflow-y-auto flex flex-col justify-center items-center relative">
      <section className="inline-flex flex-col items-center gap-8 text-center w-full">
        <div className="box-border h-[20vh]flex justify-center items-center">
          <QuestionTitle text={currentQuestion.text} />
        </div>
        <div>
          <RatingScale
            //descriptions={answerDescriptions[currentQuestion.id]}
            onRatingSelect={handleRatingSelect}
            selectedRating={selectedRating}
          />
          {/* Descriptions Below Rating Scale */}
            <div className="flex justify-between w-full mt-8 px-0">
            <span className="text-xl text-gray-700">{answerDescriptions[currentQuestion.id][1]}</span>
            <span className="text-xl text-gray-700">{answerDescriptions[currentQuestion.id][10]}</span>
          </div>

          
        </div>
      </section>
      <div className="absolute center bottom-10">
      <NavigationBar
        onNext={nextQuestion}
        isNextEnabled={!!selectedRating}
        isLastQuestion={currentQuestionIndex === questions.length - 1}
        testName = {'Kuulokysely'}
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions.length}
        submitAnswers={() =>
          submitAnswers([
            ...answers,
            { questionID: currentQuestion.id, answer: selectedRating },
          ])
        } // Ensure last answer is submitted
      />
      </div>
      <ToastContainer />
    </main>
  );
}

export default KuuloKysely;
