import React, { useState, useEffect } from 'react';
import axios from 'axios';



/// Play sound functionality
let audioContext = new (window.AudioContext || window.webkitAudioContext)(); 
let currentSrc; 

function playWave(hz, db, calib, side) {
  if (audioContext.state === 'suspended') {
    audioContext.resume();
  }

  if (currentSrc) {
    currentSrc.stop();
    currentSrc.disconnect();
  }

  let oscillator = audioContext.createOscillator();
  oscillator.type = 'sine';
  oscillator.frequency.setValueAtTime(hz, audioContext.currentTime);

  let gainNode = audioContext.createGain();
  let dbMultiplier = 10 ** ((db + calib - 80) / 20);

  gainNode.gain.value = dbMultiplier;

  let panner = audioContext.createStereoPanner();
  panner.pan.setValueAtTime(side, audioContext.currentTime);

  oscillator.connect(gainNode);
  gainNode.connect(panner);
  panner.connect(audioContext.destination);

  oscillator.start();
  currentSrc = oscillator;
}

function stopSound() {
  if (currentSrc) {
    currentSrc.stop();
    currentSrc.disconnect();
    currentSrc = null;
  }
}

/// Calibration box 


const CalibrationBox = ({ onCalibrationSave, onSelectChange, fetchCalibrationData }) => {
  const [side, setSide] = useState('-1'); // Default to Left (-1)
  const [frequency, setFrequency] = useState('125'); // Default to 125Hz
  const [dbLevel, setDbLevel] = useState('-10'); // Default db level
  const [calibrationFactor, setCalibrationFactor] = useState('');

  // Handle saving calibration factor for a single value
  const handleSaveSingle = async () => {
    if (side && frequency && dbLevel && calibrationFactor) {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/AudiometryCalibrationSettings/save`, {
          side,
          frequency,
          dbLevel,
          calibrationFactor,
        });
        // Re-fetch the calibration data after saving
        fetchCalibrationData(side, frequency);
      } catch (error) {
        console.error('Error saving calibration data:', error);
      }
    }
  };

  // Handle saving calibration factor for all dB levels
  const handleSaveAll = async () => {
    if (side && frequency && calibrationFactor) {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/AudiometryCalibrationSettings/saveAll`, {
          side,
          frequency,
          calibrationFactor,
        });
        // Re-fetch the calibration data after saving
        fetchCalibrationData(side, frequency);
      } catch (error) {
        console.error('Error saving calibration data:', error);
      }
    }
  };

  // Handle side and frequency change
  useEffect(() => {
    onSelectChange(side, frequency);
  }, [side, frequency, onSelectChange]);


  //////////////////////////////////////    /
  // Play sound functionalir

    // Play sound without calibration
    const handlePlaySound = () => {
      playWave(parseFloat(frequency), parseFloat(dbLevel), 0, parseFloat(side));
    };
  
    // Play sound with calibration
    const handlePlayWithCalibration = () => {
      playWave(parseFloat(frequency), parseFloat(dbLevel), parseFloat(calibrationFactor), parseFloat(side));
    };





  return (
    <div className="calibration-box border border-gray-300 p-4 rounded-md">
      <h3 className="text-lg font-bold mb-4">Kalibrointi</h3>

      {/* Side Dropdown */}
      <div className="flex justify-between mb-2">
        <label htmlFor="sideSelect">Valitse puoli:</label>
        <select
          id="sideSelect"
          value={side}
          onChange={(e) => setSide(e.target.value)}
          className="border p-2 w-1/3"
        >
          <option value="-1">Vasen</option>
          <option value="1">Oikea</option>
        </select>
      </div>

      {/* Frequency Dropdown */}
      <div className="flex justify-between mb-2">
        <label htmlFor="frequencySelect">Taajuus:</label>
        <select
          id="frequencySelect"
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)}
          className="border p-2 w-1/3"
        >
          <option value="125">125Hz</option>
          <option value="250">250Hz</option>
          <option value="500">500Hz</option>
          <option value="750">750Hz extra</option>
          <option value="1000">1000 Hz</option>
          <option value="1500">1500 Hz extra</option>
          <option value="2000">2000 Hz</option>
          <option value="3000">3000 Hz extra </option>
          <option value="4000">4000 Hz</option>
          <option value="5000">5000 Hz extra</option>
          <option value="6000">6000 Hz</option>
          <option value="8000">8000 Hz</option>
          <option value="10000">10000 Hz extra</option>
          <option value="12000">12000 Hz extra</option>
          <option value="14000">14000 Hz extra</option>
          <option value="16000">16000 Hz extra</option>
          {/* Add more frequencies as necessary */}
        </select>
      </div>

      {/* dB Level Input */}
      <div className="flex justify-between mb-2">
        <label htmlFor="dbLevelInput">dB taso:</label>
        <input
          type="text"
          id="dbLevelInput"
          value={dbLevel}
          onChange={(e) => setDbLevel(e.target.value)}
          className="border p-2 w-1/3"
        />
      </div>

      {/* Calibration Factor Input */}
      <div className="flex justify-between mb-2">
        <label htmlFor="calibrationFactorInput">Kalibrointiarvo:</label>
        <input
          type="text"
          id="calibrationFactorInput"
          value={calibrationFactor}
          onChange={(e) => setCalibrationFactor(e.target.value)}
          className="border p-2 w-1/3"
        />
      </div>

        {/* Sound Control Buttons */}
        <div className="flex justify-center gap-4 mb-4">
        <button
          onClick={handlePlaySound}
          className="bg-blue-500 text-white px-6 py-2 rounded-lg"
        >
          Play Sound
        </button>
        <button
          onClick={stopSound}
          className="bg-red-500 text-white px-6 py-2 rounded-lg"
        >
          Stop Sound
        </button>
        <button
          onClick={handlePlayWithCalibration}
          className="bg-green-500 text-white px-6 py-2 rounded-lg"
        >
          Play with Calibration
        </button>
      </div>

      {/* Save Buttons */}
      <div className="flex justify-center mt-2">
        <button onClick={handleSaveSingle} className="bg-blue-500 text-white px-6 py-2 rounded-lg mr-2">
          Save Single Value
        </button>
        <button onClick={handleSaveAll} className="bg-green-500 text-white px-6 py-2 rounded-lg">
          Save to all values
        </button>
      </div>
    </div>
  );
};

export default CalibrationBox;
