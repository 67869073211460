import React from "react";


function RatingScale({ onRatingSelect, selectedRating }) {
  const ratings = [
    { value: 1 },
    ...Array.from({ length: 8 }, (_, i) => ({ value: i + 2 })),
    { value: 10 },
  ];


  return (
    <div className="flex justify-center w-full">
      {/* Buttons Container */}
      <div className="flex flex-wrap gap-6 justify-center relative">
        {ratings.map((rating) => (
          <button
            key={rating.value}
            id={`button-${rating.value}`}
            className={`w-20 h-20 rounded-2xl flex justify-center items-center text-3xl font-bold transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 shadow-[6px_4px_1px_rgba(0,0,0,1),_0px_0px_4px_rgba(255,255,255,0.2)] border-2 text-gray-800
              ${
                selectedRating === rating.value
                  ? "bg-[#7E4AE7] text-white border-[#7E4AE7]" // Selected state
                  : "bg-white border-black" // Default state
              }`}
            aria-label={`Rate ${rating.value}`}
            onClick={() => onRatingSelect(rating.value)}
          >
            {rating.value}
          </button>
        ))}
      </div>
    </div>
  );
}

export default RatingScale;
