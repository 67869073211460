import React, {useEffect, useState } from 'react';
import axios from 'axios'; // For making API requests

const SettingsBox = () => {
const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [latestSettings, setLatestSettings] = useState(null);
  const [allSettings, setAllSettings] = useState([]);
  const [selectedSettingId, setSelectedSettingId] = useState('');  
  const [responseTime, setResponseTime] = useState('5000'); // Default to 5000 ms
  const [restart, setRestart] = useState(false);
  const [mode, setMode] = useState('0');
  const [rounds, setRounds] = useState('2');
  const [noticeEnabled, setNoticeEnabled] = useState(false);
  const [noticeLevels, setNoticeLevels] = useState({
    notice1000: '20',
    notice125: '20',
    notice2000: '20',
    notice250: '20',
    notice4000: '20',
    notice500: '20',
    notice8000: '20',
  });
  const [selectedFrequency, setSelectedFrequency] = useState('125');
  const [headphoneModel, setHeadphoneModel] = useState('DD65v2');

  useEffect(() => {
    fetchLatestSettings();
    fetchAllSettings();
  }, []);

  const fetchLatestSettings = async () => {
    try {
      const response = await axios.get(`${apiUrl}/AudiometrySettings/latest`);
      console.log('Latest settings response:', response); // Add this line
      if (response.data.success) {
        const settings = response.data.data;
        setLatestSettings(settings);
        // Update individual state variables with the fetched data
        setResponseTime(settings.responseTime);
        setRestart(settings.restart);
        setMode(settings.mode);
        setRounds(settings.rounds);
        setNoticeEnabled(settings.noticeEnabled);
        setNoticeLevels(settings.noticeLevels);
        setSelectedFrequency(settings.selectedFrequency);
        setHeadphoneModel(settings.headphoneModel);
      }
    } catch (error) {
      console.error('Error fetching latest settings:', error);
    }
  };


  const fetchAllSettings = async () => {
    try {
      const response = await axios.get(`${apiUrl}/AudiometrySettings/all`);
      console.log('All settings response:', response); // Add this line
      if (response.data.success) {
        const settings = response.data.data;

        setAllSettings(response.data.data);
        
        setResponseTime(settings.responseTime);
        setRestart(settings.restart);
        setMode(settings.mode);
        setRounds(settings.rounds);
        setNoticeEnabled(settings.noticeEnabled);
        setNoticeLevels(settings.noticeLevels);
        setSelectedFrequency(settings.selectedFrequency);
        setHeadphoneModel(settings.headphoneModel);
      }
    } catch (error) {
      console.error('Error fetching all settings:', error);
    }
  };


  const handleSettingChange = async (event) => {
    const selectedId = event.target.value;
    setSelectedSettingId(selectedId);

    // Fetch and load the selected setting by its ID
    if (selectedId) {
      try {
        const response = await axios.get(`${apiUrl}/AudiometrySettings/${selectedId}`);
        if (response.data.success) {
          const settings = response.data.data;
          setLatestSettings(settings);
          // Update individual state variables with the fetched data
          setResponseTime(settings.responseTime);
          setRestart(settings.restart);
          setMode(settings.mode);
          setRounds(settings.rounds);
          setNoticeEnabled(settings.noticeEnabled);
          setNoticeLevels(settings.noticeLevels);
          setSelectedFrequency(settings.selectedFrequency);
          setHeadphoneModel(settings.headphoneModel);
        }
      } catch (error) {
        console.error('Error fetching selected setting:', error);
      }
    }
  };

    // Delete selected setting
    const handleDeleteSetting = async () => {
        if (!selectedSettingId) {
          alert('No setting selected to delete.');
          return;
        }
    
        try {
          await axios.delete(`${apiUrl}/AudiometrySettings/${selectedSettingId}`);
          alert('Setting deleted successfully.');
    
          // Fetch latest settings and update the UI after deletion
          fetchAllSettings();
          fetchLatestSettings();
        } catch (error) {
          console.error('Error deleting the selected setting:', error);
          alert('Failed to delete the setting.');
        }
      };





  // Suggested response times for the input
  const responseTimeSuggestions = [1000, 2000, 3000, 4000, 5000, 6000];

  const handleSaveSettings = async () => {
    const settingsData = {
      responseTime,
      restart,
      mode,
      rounds,
      noticeEnabled,
      noticeLevels,
      selectedFrequency,
      headphoneModel,
    };

    try {
      // Send the data to your backend API
      await axios.post(`${apiUrl}/AudiometrySettings/create`, settingsData);
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Failed to save settings.');
    }
  };

  return (
    <div className="settings-box border border-gray-300 p-4 rounded-md">
      <h3 className="text-lg font-bold mb-2">Asetukset</h3>

      {/* Dropdown to load previous settings */}
      <div className="flex justify-between mb-2">
        <label htmlFor="settingsDropdown">Load previous settings:</label>
        <select
          id="settingsDropdown"
          value={selectedSettingId}
          onChange={handleSettingChange}
          className="border p-2 w-1/3"
        >
          <option value="">Select saved settings</option>
          {allSettings.map((setting) => (
            <option key={setting._id} value={setting._id}>
              {new Date(setting.createdAt).toLocaleString()} {/* Format date as needed */}
            </option>
          ))}
        </select>
      </div>

      <div className="flex justify-end mb-4">
        <button 
          onClick={handleDeleteSetting} 
          className="bg-red-500 text-white px-4 py-2 rounded-lg"
        >
          Delete Selected Setting
        </button>
      </div>

      {/* Vastausaika Input with Suggestions */}
      <div className="flex justify-between mb-2">
        <label htmlFor="responseTime" className="mr-2">Vastausaika (ms):</label>
        <input 
          type="text" 
          list="responseTimeSuggestions" 
          id="responseTime" 
          value={responseTime} 
          onChange={(e) => setResponseTime(e.target.value)} 
          className="border p-2 w-1/3"
        />
        <datalist id="responseTimeSuggestions">
          {responseTimeSuggestions.map((time) => (
            <option key={time} value={time} />
          ))}
        </datalist>
      </div>

      {/* Restart Checkbox */}
      <div className="flex justify-between mb-2">
        <label htmlFor="restart">1000 Hz aloita alusta:</label>
        <input 
          type="checkbox" 
          id="restart" 
          checked={restart} 
          onChange={(e) => setRestart(e.target.checked)} 
        />
      </div>

      {/* Mode Input */}
      <div className="flex justify-between mb-2">
        <label htmlFor="mode">Testausjärjestys 0 = O + V:</label>
        <input 
          type="text" 
          id="mode" 
          value={mode} 
          onChange={(e) => setMode(e.target.value)} 
          className="border p-2 w-1/3"
        />
      </div>

      {/* Rounds Input */}
      <div className="flex justify-between mb-2">
        <label htmlFor="rounds">Saman dB tason testaus/Taajuus:</label>
        <input 
          type="text" 
          id="rounds" 
          value={rounds} 
          onChange={(e) => setRounds(e.target.value)} 
          className="border p-2 w-1/3"
        />
      </div>

      {/* Notice Checkbox */}
      <div className="flex justify-between mb-2">
        <label htmlFor="noticeEnabled">Ilmoita potilaalle huonosta kuulosta:</label>
        <input 
          type="checkbox" 
          id="noticeEnabled" 
          checked={noticeEnabled} 
          onChange={(e) => setNoticeEnabled(e.target.checked)} 
        />
      </div>

      {/* Headphone Model */}
      <div className="flex justify-between mb-2">
        <label htmlFor="headphoneModel">Kuulokemalli:</label>
        <input 
          type="text" 
          id="headphoneModel" 
          value={headphoneModel} 
          onChange={(e) => setHeadphoneModel(e.target.value)} 
          className="border p-2 w-1/3"
        />
      </div>

      {/* Save Button */}
      <div className="flex justify-center mt-2">
        <button 
          onClick={handleSaveSettings} 
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          Tallenna asetukset
        </button>
      </div>

            {/* Delete Button */}

    </div>
  );
};

export default SettingsBox;