import React, { useState, useEffect } from 'react';
import SettingsBox from './SettingsBox';
import CalibrationBox from './CalibrationBox';
import FrequencyDataBox from './FrequencyDataBox';
import SettingsNavbar from '../../Settingspage/SettingsNavbar';
import ButtonUtil from '../../../Global/ButtonUtil';
import { useNavigate } from 'react-router-dom';
import { HeadPhoneIconv1, HomeIcon, TestIconv1 } from '../../../../assets/Icons';
import axios from 'axios';

const AudiogramCalibrationMain = () => {
  const navigate = useNavigate();
  const [calibrationData, setCalibrationData] = useState([]);
  const [side, setSide] = useState('-1'); // Default side (Left)
  const [frequency, setFrequency] = useState('125'); // Default frequency (125Hz)

  // Fetch calibration data on side or frequency change
  useEffect(() => {
    fetchCalibrationData(side, frequency);
  }, [side, frequency]);

  // Fetch calibration data from backend based on selected side and frequency
  const fetchCalibrationData = async (selectedSide, selectedFrequency) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/AudiometryCalibrationSettings/${selectedSide}/${selectedFrequency}`);
      if (response.data.success) {
        setCalibrationData(response.data.data);
      } else {
        setCalibrationData([]); // Set empty array if no data found
      }
    } catch (error) {
      console.error('Error fetching calibration data:', error);
    }
  };

  // Handle saving the calibration data (single or all values)
  const handleCalibrationSave = async (selectedSide, selectedFrequency, dbLevel, calibrationFactor, saveAll) => {
    try {
      const endpoint = saveAll ? '/api/AudiometryCalibrationSettings/saveAll' : '/api/AudiometryCalibrationSettings/save';
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
        side: selectedSide,
        frequency: selectedFrequency,
        dbLevel,
        calibrationFactor,
      });
      // Refresh the data after saving
      fetchCalibrationData(selectedSide, selectedFrequency);
    } catch (error) {
      console.error('Error saving calibration data:', error);
    }
  };

  // Handle side and frequency selection changes in CalibrationBox
  const handleSelectChange = (selectedSide, selectedFrequency) => {
    setSide(selectedSide);
    setFrequency(selectedFrequency);
    // No need to call fetchCalibrationData here, useEffect will handle it
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <SettingsNavbar>
        <div className="flex gap-4">
          <ButtonUtil icon={HomeIcon} handleClick={() => navigate('/home')} text="Home" />
          <ButtonUtil icon={TestIconv1} handleClick={() => navigate('/Settings')} text="Takaisin asetuksiin" />
          <ButtonUtil icon={HeadPhoneIconv1} handleClick={() => navigate('/AudiogramHeadphoneCompensation')} text="Kompensointi " />
        </div>
      </SettingsNavbar>

      {/* Main Content Layout */}
      <div className="pt-10 px-4 md:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="left-section flex flex-col">
            <SettingsBox />
            <CalibrationBox onCalibrationSave={handleCalibrationSave} onSelectChange={handleSelectChange} fetchCalibrationData={fetchCalibrationData}  />
          </div>
          <div className="right-section flex flex-col">
            <FrequencyDataBox calibrationData={calibrationData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudiogramCalibrationMain;
