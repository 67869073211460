import React, { useState } from 'react';
import GlobalNavbar from '../../../utils/GlobalNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserDisplaySection from '../../Global/UserDisplaySection';
import ContentWrapper from '../../../utils/ContentWrapper';
import { CheckCircleIcon, HeadPhoneIcon } from '../../../assets/Icons';
import { setSoundTestIndex } from '../../../Slices/soundSilce';

const TestLandingPage = () => {
    /* Config */
    const user = useSelector((state) => state.authState.user) || JSON.parse(localStorage.getItem('user'));
    const soundTestFromStore = useSelector((state) => state.soundState.soundTest);
    const [soundTest] = useState(soundTestFromStore);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log({ soundTest });






    return (user &&
        <div className='min-h-screen bg-gray-100 flex flex-col items-center'>
            <GlobalNavbar />

            <div className='pt-0 flex justify-center flex-col px-4  min-w-[50%] overflow-auto'>
                <UserDisplaySection user={user} />
                <ContentWrapper className=''>
                    <div className='px-2 flex items-center gap-x-2 '>
                        <img src={HeadPhoneIcon} className='size-8' alt=''/>
                        <p className='text-2xl  text-[#344054] font-bold'>Sinulle määrätyt kuulotestit</p>
                    </div>
                    <div className='px-4 mt-8'>
                        {/* Map to display test options below */}
                        {soundTest.map((test, index) => (
                            <div className={`text-sm md:text-sm lg:text-[24px] flex justify-between py-2 border-b border-gray-200 ${index === 0 ? 'border-t border-gray-200' : ''}`} key={index}>
                                <div className='flex gap-x-1 relative'>
                                    <div className='flex items-center'>
                                        <p className='text-md font-bold'>
                                            {test.name}
                                        </p>
                                    </div>
                                    <p className='text-[12px] ml-1 flex items-center  '>{test.duration}</p>
                                </div>


                                <div>
                                    {
                                        !test.completed ?
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    dispatch(setSoundTestIndex(index));
                                                    navigate(test.navigate);
                                                }}
                                                className='w-full bg-white text-[#53389E] border border-[#53389E] py-6 px-6 transition-colors duration-300 ease-out hover:bg-purple-600 hover:text-white hover:border-purple-600 rounded-xl'
                                            >
                                                Aloita testi
                                            </button>
                                            :
                                            <div className='flex items-center gap-x-[0px] relative py-6 px-6'>
                                                <p className='text-[12px] font-bold'>Suoritettu </p>
                                                <div className='relative p-2'>
                                                    <img src={CheckCircleIcon} className='size-3 absolute right-0 top-1/2 -translate-y-[40%]' alt=''/>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <div>
                        <button
                            className='w-full bg-blue-500 text-white py-2 px-4 mt-8 rounded-md'
                            onClick={handleCompleteRandomTest}
                        >
                            Complete
                        </button>
                    </div> */}
                </ContentWrapper>

            </div>
        </div>
    );
};

export default TestLandingPage;