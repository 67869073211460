import React from 'react';

function QuestionTitle({ text }) {
    return (
        <header className="flex flex-col max-w-[1200px] font-bold text-center text-slate-700 w-full">
            <div className="flex flex-col justify-center w-full max-md:max-w-full">
                <h1 className="text-xl uppercase tracking-[.8px] max-md:max-w-full">
                    KuuloKysely
                </h1>
                <h2 className="justify-center items-center font-display-lg-bold font-[number:var(--display-lg-bold-font-weight)] text-black text-5xl text-center tracking-[var(--display-lg-bold-letter-spacing)] leading-[var(--display-lg-bold-line-height)] [font-style:var(--display-lg-bold-font-style)] ">
                    {text}
                </h2>
            </div>
        </header>
    );
}

export default QuestionTitle;
