import isElectron from "../../../Functions/isElectron";

export let globalState = {
  settings: null,
  calibration: null,
  compensation: null,
};

// Function to determine if the app is running in an Electron environment

// Function to fetch data based on the environment
async function fetchData() {
  if (
    globalState.settings &&
    globalState.calibration &&
    globalState.compensation
  ) {
    console.log("Data already fetched, no need to re-fetch.");
    return;
  }

  try {
    if (isElectron()) {
      // Fetch data from local files if in Electron
      console.log("Are we here");
      globalState.settings = await fetchLocalSettings()
      globalState.calibration = await fetchLocalCalibration();
      globalState.compensation = await fetchLocalCompensation();
    } else {
      console.log("Server Are we here");
      // Fetch data from MongoDB via your server if not in Electron
      globalState.settings = await fetchFromServer(
        `${process.env.REACT_APP_BACKEND_URL}/AudiometrySettings/latest`
      );
      globalState.calibration = await fetchFromServer(
        `${process.env.REACT_APP_BACKEND_URL}/AudiometryCalibrationSettings/getAllCalibrations`
      );
      globalState.compensation = await fetchFromServer(
        `${process.env.REACT_APP_BACKEND_URL}/HeadphoneCompensationSettings/getAllCompensation`
      );
    }
    console.log("Data fetched and stored in globalState:", globalState);
  } catch (error) {
    console.error("Error fetching initial data:", error);
  }
}

// Function to fetch data from a local file
async function fetchLocalSettings(filename) {
  try {
    const response = await window.electron.loadAudiometrySettings();
    console.log({ response });
    return response.data;
  } catch (error) {
    console.error(`Error reading ${filename}:`, error);
    return null;
  }
}

async function fetchLocalCalibration(filename) {
    try {
      const response = await window.electron.loadAudiometryCalibration();
      console.log({ response });
      return response.data;
    } catch (error) {
      console.error(`Error reading ${filename}:`, error);
      return null;
    }
  }

  async function fetchLocalCompensation(filename) {
    try {
      const response = await window.electron.loadAudiometryCompensation();
      console.log({ response });
      return response.data;
    } catch (error) {
      console.error(`Error reading ${filename}:`, error);
      return null;
    }
  }

//endpoint = `http://localhost:8001/api/AudiometrySettings/getAllSettings`

// Function to fetch data from the server (MongoDB)
async function fetchFromServer(endpoint) {
  try {
    const response = await fetch(endpoint);
    //console.log("Fetched settings", globalState)
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.error(`Error fetching from server: ${endpoint}`, error);
    return null;
  }
}

function getParam() {
  const settings = globalState.settings?.data?.[0] || globalState.settings.data || globalState.settings;
  if (!settings) {
    console.error("Settings not loaded.");
    return null;
  }

  console.log(settings);
  const mode = settings.mode !== undefined ? parseInt(settings.mode, 10) : 0;
  const ResponseTime = parseInt(settings.responseTime, 10) || 5000;
  const restart = settings.restart ? 1 : 0;
  const rounds = parseInt(settings.rounds, 10) || 3;
  const noticeEnabled = settings.noticeEnabled ? 1 : 0;
  const headphoneModel = settings.headphoneModel || "defaultModel"; // Adjust "defaultModel" as necessary

  let cal125 = [],
    cal250 = [],
    cal500 = [],
    cal1000 = [],
    cal2000 = [],
    cal4000 = [],
    cal8000 = [];
  let max125 = 100,
    max250 = 100,
    max500 = 100,
    max1000 = 100,
    max2000 = 100,
    max4000 = 100,
    max8000 = 100;

  const noticeLevels = settings.noticeLevels || {};
  const notice125 = parseInt(noticeLevels.notice125, 10) || 20;
  const notice250 = parseInt(noticeLevels.notice250, 10) || 20;
  const notice500 = parseInt(noticeLevels.notice500, 10) || 20;
  const notice1000 = parseInt(noticeLevels.notice1000, 10) || 20;
  const notice2000 = parseInt(noticeLevels.notice2000, 10) || 20;
  const notice4000 = parseInt(noticeLevels.notice4000, 10) || 20;
  const notice8000 = parseInt(noticeLevels.notice8000, 10) || 20;

  return {
    mode,
    ResponseTime,
    rounds,
    restart,
    headphoneModel,
    calibrationLevels: {
      cal125,
      cal250,
      cal500,
      cal1000,
      cal2000,
      cal4000,
      cal8000,
    },
    maxLevels: {
      max125,
      max250,
      max500,
      max1000,
      max2000,
      max4000,
      max8000,
    },
    noticeLevels: {
      notice125,
      notice250,
      notice500,
      notice1000,
      notice2000,
      notice4000,
      notice8000,
      noticeEnabled,
    },
  };
}

function getCalibrationFactor(side, hz, dbLevel) {
  const calibration =
    globalState.calibration?.data?.[0] || globalState.calibration?.data || globalState.calibration;
  if (!calibration) {
    console.error("Calibration data not loaded.");
    return 0.512; // Default to 0.5 if not found
  }

  // Determine the side (right or left ear)
  const earKey = side === 1 ? "1" : "-1"; // '1' for right ear, '-1' for left ear
  const earCalibrationData = calibration[earKey];

  if (!earCalibrationData) {
    console.error(`No calibration data found for ear: ${earKey}`);
    return 0.5;
  }

  // Ensure that the frequency exists in the calibration data
  const frequencyData = earCalibrationData[hz.toString()];
  if (!frequencyData) {
    console.error(`No calibration data found for frequency: ${hz}`);
    return 0.5;
  }

  // Find the entry that matches the desired dbLevel
  const calibData = frequencyData.find(
    (entry) => entry.dbLevel === dbLevel.toString()
  );
  if (!calibData) {
    console.error(
      `No calibration data found for dbLevel: ${dbLevel} at frequency: ${hz}`
    );
    return 0.5;
  }

  // Return the calibrationFactor
  return parseFloat(calibData.calibrationFactor);
}

//let compensationData

async function getCompensationFactor(model, hz) {
  const compensationData =
    globalState.compensation?.message?.[0]?.headphoneModel || globalState.compensation?.headphoneModel;
  console.log(`Fetching compensation for model: ${model}, frequency: ${hz}`);
  console.log(`Compensation Data:`, compensationData);

  if (!compensationData) {
    console.error("Compensation data is undefined or empty.");
    return 0; // Return a default value if compensation data is missing
  }

  const modelData = compensationData[model]; // Get the data for the selected model
  if (!modelData) {
    console.error(`No compensation data found for model: ${model}`);
    return 0; // Return 0 if the model is not found
  }

  const compensationFactor =
    modelData.frequencies[hz.toString()]?.compensationFactor;
  if (!compensationFactor) {
    console.error(`No compensation factor found for frequency: ${hz}`);
    return 0; // Return 0 if compensation factor is missing for the frequency
  }

  return parseFloat(compensationFactor) || 0; // Ensure a numeric value is returned
}

export { getParam, getCalibrationFactor, getCompensationFactor, fetchData };
