import React, { useEffect, useState } from 'react';
import { Lock_Icon } from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken, setUser } from '../../../Slices/authSlice';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const [loginInformation, setLoginInformation] = useState({
        firstName: '',
        lastName: '',
        idNumber: '',
        email: '',
        phoneNumber: ''
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setLoginInformation({
            ...loginInformation,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, idNumber } = loginInformation;
        if (!firstName || !lastName || !idNumber) {
            toast.error('ALL FIELDS REQUIRED', { position: 'top-right' });
            return;
        }

        try {
            setLoading(true);

            if (window.electron && window.electron.showMessageBox) {
                // Running in Electron, use the Electron message box
                // const response = await window.electron.showMessageBox({
                //     type: 'warning',
                //     buttons: ['OK'],
                //     title: 'Environment Status',
                //     message: '[+] You are in Electron Environment'
                // });
                // if (response === 0) {
                //     console.log('OK button was clicked');
                // }

            } else {
                // If it's not Electron, show a simple browser alert
                // const confirmation = window.confirm("You are in a browser environment. Do you want to proceed?");
                // const confirmation = window.confirm("You are in a browser environment. Do you want to proceed?");
                // if (!confirmation) {
                //     setLoading(false);
                //     return;
                // }
                setLoading(false);
            }
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/patient/login`,
                {
                    firstName,
                    lastName,
                    socialSecurityNumber: idNumber
                }
            );

            const { data } = response;
            if (data.status && data.status === false) {
                throw new Error(data.message || "Something went wrong");
            }
            // Set the user and token in the Redux store
            dispatch(setUser(data.user));
            dispatch(setToken(data.token));
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('token', JSON.stringify(data.token));
            toast.success(data.message);
            // Ensure correct navigation after login
            navigate("/UserDetailsPage", { replace: true });
        } catch (error) {
            console.error("Error during login:", error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setSubmitted(false);
        navigate('/LandingPage');
    };

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        console.log({ storedUser });
        console.log(storedUser === 'undefined');
        if (storedUser &&  storedUser !== 'undefined' && storedUser !== undefined && storedUser !== null) {
            const parsedUser = JSON.parse(storedUser);
            setLoginInformation({
                firstName: parsedUser.firstName || '',
                lastName: parsedUser.lastName || '',
                idNumber: parsedUser.idNumber || parsedUser.socialSecurityNumber || '',
                email: parsedUser.email || '',
                phoneNumber: parsedUser.phoneNumber || ''
            });
        }
    }, []);

    return (
        <div className='h-screen bg-gray-700 flex items-center justify-center'>
            <div className='bg-white p-6 rounded-lg shadow-lg w-80 relative'>
                <form onSubmit={handleSubmit}>
                    <ToastContainer />
                    <div className='flex justify-center'>
                        <img src={Lock_Icon} alt="Lock Icon" />
                    </div>
                    <h2 className='text-center text-xl text-[#101828] font-bold mt-4 mb-4'>Syötä tietosi</h2>
                    <p className='text-center mb-4'>Syötä tiedot ja klikkaa vahvista</p>

                    <div className='mb-4'>
                        <label className='block text-[#101828] font-bold '>Etunimi</label>
                        <input
                            type='text'
                            name='firstName'
                            value={loginInformation.firstName}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                            placeholder='Pekka'
                        />
                    </div>

                    <div className='mb-4'>
                        <label className='block text-[#101828] font-bold '>Sukunimi</label>
                        <input
                            type='text'
                            name='lastName'
                            value={loginInformation.lastName}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                            placeholder='Potilas'
                        />
                    </div>

                    <div className='mb-4'>
                        <label className='block text-[#101828] font-bold '>Tutkimustunnus</label>
                        <input
                            type='text'
                            name='idNumber'
                            value={loginInformation.idNumber}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                            placeholder=''
                        />
                        <small className='text-[#e65151] font-bold'>Älä käytä henkilötunnusta</small>
                    </div>

                    <div className='flex flex-col mt-1'>
                        <button
                            type='submit'
                            className='w-full bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md'
                            disabled={loading}
                        >
                            Vahvista
                        </button>
                        <button
                            type='button'
                            className='transition-colors duration-300 ease-out w-full bg-gray-200 text-gray-700 p-2 rounded-md mt-2 hover:bg-gray-300'
                            onClick={handleCancel}
                        >
                            Peruuta
                        </button>
                    </div>
                </form>

                {submitted && (
                    <div className='mt-6'>
                        <h3 className='text-lg font-bold'>Syötetyt tiedot:</h3>
                        <p><strong>Etunimi:</strong> {loginInformation.firstName}</p>
                        <p><strong>Sukunimi:</strong> {loginInformation.lastName}</p>
                        <p><strong>Henkilötunnus:</strong> {loginInformation.idNumber}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginPage;
