// Change folder path at suync to main

///Change logic from

// copy old code from flask app

import React, {useState, useEffect, useRef} from "react";
import { useNavigate } from 'react-router-dom';
import { PauseIcon, InfoIcon } from "../../../assets/Icons"
import { getParam, getCalibrationFactor, getCompensationFactor } from './fetchParam';
import { globalState, fetchData } from './fetchParam';
// import  CustomModal from './AudiometryModal';
import NavigationBar from './AudiogramNavigationBar';
import AbortPage from '../../Modals/ModalAbortTest'; // Modal for aborting the test
import HelpPageModal from '../../Modals/ModalHelpPage'; // Modal for help
// import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import isValidJSON from "../../../Functions/IsValidJSON";





const Audiogram2 = () => {
    const [isExaminationRunning, setIsExaminationRunning] = useState(false);
    const [buttonPressCount, setButtonPressCount] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    //const [keyHandled, setKeyHandled] = useState(false);
    const [lightColor, setLightColor] = useState("green");
    const [interrupt, setInterrupt]  = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pressStartTime, setPressStartTime] = useState(null);
    const [pressDuration, setPressDuration] = useState(null);
    const [pressed, setPressed] = useState(false);
    const [currentFrequencyIndex, setCurrentFrequencyIndex] = useState(0);
    const [currentIntensity, setCurrentIntensity] = useState(50);
    const [currentEarIndex, setCurrentEarIndex] = useState(0);

    const [isTestRunning, setIsTestRunning] = useState(false); // Tracks if the test is running
    const [isNextEnabled, setIsNextEnabled] = useState(false); // Tracks if "Seuraava" button is enabled
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false); // Tracks if the help modal is open
    const [isTestPaused, setIsTestPaused] = useState(false); 
    const [isTestStarted, setIsTestStarted] = useState(false); 

    
    //Immediately change state
    const interruptRef = useRef(false);
    const pressedRef = useRef(pressed);
    const heardRef = useRef(false);
    const HeardOnceRef = useRef(false)
    const StartFlagRef = useRef(false);
    const isExaminationRunningRef = useRef(false)
    const isResumingRef = useRef(false)
    const currentEarIndexRef = useRef(0);
    const currentIntensityRef = useRef(50); // Properly initialize currentIntensity
    const buttonDisabledRef = useRef(false); // Create a ref for buttonDisabled
    const pressStartTimeRef = useRef(null);
    const RefSoundStartTime = useRef({ previous: null, current: null });
    const keyHandledRef = useRef(false); // y key pressing handling
    const testPhaseRef =useRef(0)
    const EnableContinueButtonRef = useRef(false)

    const tokenFromStorage = localStorage.getItem("token");
    const token = useSelector((state) => state.authState.token) || (tokenFromStorage && isValidJSON(tokenFromStorage) ? JSON.parse(tokenFromStorage) : null);

    const ipcRenderer = isElectron() ? window.require('electron').ipcRenderer : null;
    const navigate = useNavigate();
    var uncertain = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    var startTime, endTime;

    var resultArray = [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null];
    var db; // Global declaration
    var sum 
    var maxDb

    var clickTimer;
    var testInfoData
    var ctrArray =  []; //counter array
    var retest_ctr = 1 // Added: retest counter (max 2)
    var oneKHz_ctr = 1 // Added: the number of occurene of 1000Hz in test
    var oneKTh1 = 0 //Threshold1 in 1000Hz
    var oneKTh2 = 0 //Threshold2 in 1000Hz
    var oneKTh3 = 0 //Threshold3 in 1000Hz
    var oneKTh = 0 // Threshold value in 1000Hz
    var test_accR = 0 //0: accurate data, 1: inaccurate data in right ear (ex. When a patient's responses in 1K Hz differ by more than 10 dB on three times.)
    var test_accL=0 //0: accurate data, 1: inaccurate data in left ear (ex. When a patient's responses in 1K Hz differ by more than 10 dB on three times)
    var valid_data = 0 //0: valid data, 1: invalid data (ex. When a patient discontinues a test midway.)
    var total_warmUp_ctrR = 0 //Warm-up test counter on right ear for 1000Hz
    var total_warmUp_ctrL = 0 //Warm-up test counter on left ear for 1000Hz
    var total_main_ctrR = 0 //The number of main test steps for the right ear to find thresholds for each patient
    var total_main_ctrL = 0 //The number of main test steps for the left ear to find thresholds for each patient
    var total_step_ctrR = 0 //The total number of steps for the right ear
    var total_step_ctrL = 0 //The total number of steps for the left ear

    var json = {
        testInfo: [], // Array to store sound data    
        results: [],
        dbLimits: {},  
        sound: [] // Array to store test information
    };

    let responseDuration = null; // = pressStartTime - soundStartTime
    var maxBtnPress = 5; // The maximum number of button press allowed.
    var TotalTime = null;

    var audioContext = new (window.AudioContext || window.webkitAudioContext)(); // Create a single instance of AudioContext
    var currentSrc; // Variable to keep track of the currently playing source


function isElectron() {
    return typeof window !== 'undefined' && window.process && window.process.type === 'renderer';
}


function median(a, b, c) {
const sorted = [a, b, c].sort((x, y) => x - y);  // Sort the integers
const middle = Math.floor(sorted.length / 2);
const isEven = sorted.length % 2 === 0;
// Return the middle value or average of two middle values
return isEven ? (sorted[middle - 1] + sorted[middle]) / 2 : sorted[middle];
}

async function fetchAndInitialize() {
    await fetchData(); // Ensure this function is awaited
}


async function initializeParams() {   // Fetch parameters at the start
    try {
        const params = getParam();
        if (params) {
            const { mode, ResponseTime, rounds, restart, headphoneModel, calibrationLevels, maxLevels, noticeLevels } = params;
            //console.log(`Headphone Model: ${headphoneModel}`);
            console.log(params)
            return params;
        }
    } catch (error) {
        console.error("Error initializing parameters:", error);
    }
    return null;
    }

fetchAndInitialize()


  // Effect to attach and detach key press listeners
useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('keyup', handleKeyPress);

    return () => {
        window.removeEventListener('keydown', handleKeyPress);
        window.removeEventListener('keyup', handleKeyPress);
    };
  }, []); // Dependencies: handle changes to the pressStartTime state

useEffect(() => {
    buttonDisabledRef.current = buttonDisabled; // Sync the state with the ref
}, [buttonDisabled]);  


useEffect(() => {
pressedRef.current = pressed;  // Sync the ref with the latest `pressed` value
}, [pressed]);

useEffect(() => {
    if (!globalState.settings || !globalState.calibration || !globalState.compensation) {
        fetchAndInitialize();
    }
    }, []); // Empty dependency array to ensure it only runs once on mount
    

const handleCloseAbortModal = () => { // Handles closing the abort modal
    setIsModalOpen(false); // Close the abort modal
};

const handleCloseHelpModal = () => {   // Handles closing the help modal
    setIsHelpModalOpen(false); // Close the help modal
};

const finishTest = () => { // Marks the test as finished and shows "Seuraava"
    setIsTestRunning(false);
    setIsNextEnabled(true); // Enable "Seuraava" after test completion
};

const handleClick = () => {
    if (!isExaminationRunningRef.current) {
        setIsExaminationRunning(true);
        console.log("Call examination fucntion:");
        examination();
    } else {
        console.log("A voice was heard!:");
        pressTheButton();
    }
};


// Navigate to the previous page when the user wants to leave the test
const handleNextClick = () => {
    setIsModalOpen(false); // Show the modal
    navigate('/TestLandingPage'); // Navigate to the previous page
};


function handleKeyPress(event) {
    if (event.key === 'y' || event.key === 'Y') { // Check for 'Y' or 'y'
        if (event.type === 'keydown') {
            if (!keyHandledRef.current) { // Ensure the key isn't already handled
                keyHandledRef.current = true;
                handleClick(); // Trigger the same logic as the button click
            }
        } else if (event.type === 'keyup') {
            keyHandledRef.current = false; // Reset the keyHandled flag on key release
        }
    }
}


// Function to pause the test
const pauseTest = (db) => {
    console.log("pauseTest: StartFlag1: " + StartFlagRef.current);
    EnableContinueButtonRef.current = false

    if (StartFlagRef.current === true) { // A test has already started
    console.log("pauseTest: A test has already started and paused.");
    interruptRef.current = true; // Ensure interruptRef is a ref
    isResumingRef.current = true; // Ensure isResumingRef is a ref
    setIsTestPaused(true);  // Mark test as paused
    } else {
    console.log("pauseTest: A test has not started yet.");
    interruptRef.current = true; // Ensure interruptRef is a ref
    isResumingRef.current = true; // Ensure isResumingRef is a ref
    }

    console.log("pauseTest: Pausing at intensity: " + db + "db");
    console.log("pauseTest: Pausing, current state: ", currentFrequencyIndex.current);
};


function resumeTest() {
    console.log("resumeTest: StartFlag1: " + StartFlagRef.current);
    setIsModalOpen(false); // Close the moda
    interruptRef.current = false; // Clear interrupt flag
    setIsExaminationRunning(false);

    // If the test was not started, begin at 50 dB
    if (StartFlagRef.current === false) {
        db = 50;
        currentIntensityRef.current = db
        console.log("resumeTest: Resuming, using 50 dB as start.");
    } else {
        console.log("resumeTest: Resuming at intensity: ", currentIntensityRef.current, "dB" );
        setCurrentEarIndex(currentEarIndex); // Restore the current ear
    }
    isExaminationRunningRef.current = false;
    isResumingRef.current = true
}



const pressTheButton = () => {
    if (buttonDisabledRef.current) {
        console.log("Button is disabled, skipping press.");
        //buttonPressCount + 1
        return; // Exit if the button is already disabled
    }
    pressStartTimeRef.current = Date.now();
    console.log("Button pressed!", pressStartTimeRef.current);

    setIsTestRunning(!isTestRunning); // Toggle the test running state
    setButtonDisabled(true); // Disable the button immediately
    clearTimeout(clickTimer); // Clear any previous timer to avoid conflicts

    // Increase the press count for tracking button presses
    setButtonPressCount((prevCount) => prevCount + 1); 
    console.log("pressTheButton: buttonPressCount", buttonPressCount + 1);

    if (buttonPressCount + 1 >= maxBtnPress) {
        alert("Liian monta painallusta. Yksi painallus riittää. Jatkuva napin painaminen johtaa testin hylkäämiseen.");
        pressedRef.current = false; // Immediately update ref
        console.log("pressTheButton: too many times a button is pressed. buttonPressCount: " + buttonPressCount);

        // Reset press count and disable the button
        setButtonPressCount(0); 
        setButtonDisabled(true); // Disable the button
        setTimeout(() => {
            setButtonDisabled(false); 
        }, 2000); // Re-enable after 2 seconds

    } else {
        console.log("Pressed line 365",  pressedRef.current );
        pressedRef.current = true; // Immediately update ref

        // Change circle color to light green when the button is pressed
        setLightColor("limegreen");

        // Revert the circle color to dark green after 1 second
        setTimeout(() => {
            setLightColor("green");
        }, 2000);

        console.log("Pressed line  376",  pressedRef.current );
    }

    // Disable the button for 2 seconds after any valid press
    setButtonDisabled(true);
    setTimeout(() => {
        setButtonDisabled(false); // Re-enable the button after 2 seconds
    }, 2000);

    // Reset button press count after 1 second of inactivity
    clickTimer = setTimeout(() => {
        setButtonPressCount(0);
        //setPressStartTime(null);
        setPressDuration(null);
    }, TotalTime);
};



    ////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////

    // Define the examination function at the top level
async function examination() {
    console.log("In examination", isExaminationRunningRef.current)

    if (isExaminationRunningRef.current) {
        console.log("Examination: Examination is already running or interrupted.");
        return;
        }

    isExaminationRunningRef.current = true; // Set examination as running
    const params = await initializeParams();
    if (!params) {
        console.error("Failed to initialize parameters.");
        return;
    }

    const compensation = globalState.compensation;   // Ensure compensation data is fetched
    if (!compensation) {
        console.error("Compensation data not loaded.");
        return;
    }

    startTime = new Date(); // Add this line to initialize startTime
    // console.log("Parameters loaded in examination function:", params);f

    var hzList = [1000, 2000, 4000, 8000, 1000, 500, 250, 125];
    const mode = params.mode;
    const headphoneModel = params.headphoneModel || 'defaultModel';

    var earList;
    if (mode === 0) {
        earList = [1, -1]; // Test right ear first, then left ear
    } else if (mode === 1) {
        earList = [0]; // Test both ears at the same time
    } else if (mode === 2) {
        earList = [1]; // Test right ear only
    } else if (mode === 3) {
        earList = [-1]; // Test left ear only
    }

    if (!isResumingRef.current ) {
        startTime = new Date();
        setCurrentEarIndex(0);
        setCurrentFrequencyIndex(0);
    }

    await new Promise(resolve => setTimeout(resolve, 5000));
    isExaminationRunningRef.current = true;
    

    for (let j = currentEarIndexRef.current; j < earList.length; j++) {
        //console.log("00. currentEarIndex: "+ currentEarIndex);
        //console.log("00. currentEarIndex: "+ currentEarIndexRef.current);
        //console.log("00. currentEarLength: "+ earList.length);
        currentEarIndexRef.current = j

        let side = earList[j];
        let restarted = false;
        retest_ctr = 1;

        for (let i = currentFrequencyIndex; i < hzList.length; i++) {

            if (interruptRef.current) {
                currentEarIndexRef.current = j;
                console.log("IS this reached")
                //pauseTest(db); // Break out of the loop if the test is interrupted and save db value
                return;
            }
            //console.log("savedtocurrentFrequencyIndex in examination",i)
            setCurrentFrequencyIndex(i);

        // If resuming, use currentIntensity instead of resetting to 50dB
        if (isResumingRef.current ) { //&& i === currentFrequencyIndex && j === currentEarIndexRef.current
            console.log('Resuming at saved intensity:', currentIntensityRef.current);
            db = currentIntensityRef.current; // Continue from the paused intensity
            isResumingRef.current = false; // Reset after using the saved value
        } else {
            console.log("Not resuming")
            db = 50; // Start at 50 dB for a new frequency if not resuming
            testPhaseRef.current = 0
            
        }

            StartFlagRef.current = true;

            const hz = hzList[i];
            const calib = await getCalibrationFactor(side, hz, db);
            const compensation = await getCompensationFactor(headphoneModel, hz);
            console.log(`Calibration: ${calib}, Compensation: ${compensation}`);

            // Not used for now. set to 100 dB    
            maxDb = [params.maxLevels.max1000, params.maxLevels.max2000, params.maxLevels.max4000, params.maxLevels.max8000, params.maxLevels.max1000,  params.maxLevels.max125, params.maxLevels.max250, params.maxLevels.max500];
            //console.log(maxDb)

            if (hzList[i] === 1000) {
                db = await warmUp(hzList[i], db, calib, compensation, side, params, maxDb[i]);
                db = await mainTest(hzList[i], db, calib, compensation, side, params, params.rounds, i + j * 8, maxDb[i]);
                ctrArray[i + j * 8] = oneKHz_ctr;
                oneKHz_ctr += 1;
            } else {
                var definedElements = resultArray.filter(Boolean);
                var resultArray_count = definedElements.length;

                //If a threshold in 1kHz is more than 50dB, +10dB in the next frequencies.
                console.log("examination: resultArray: "+resultArray);
                console.log("examination: resultArray_count: "+resultArray_count);
                console.log("examination: dB value "+db);

                if (resultArray_count < 5) {
                    if (resultArray[0] > 50) {
                        db = resultArray[0] + 10;
                        if (db >= 100) { //If db reaches 100dB and no response, the db will start from 50dB in the next freqency.
                            db = 50;
                        }
                        db = Math.min(Math.max(db, 50), 100);
                    }
                } else if (resultArray_count >= 5 && resultArray_count < 9) {
                    if (resultArray[4] > 50) {
                        db = resultArray[4] + 10;
                        if (db >= 100) { //If db reaches 100dB and no response, the db will start from 50dB in the next freqency.
                            db = 50;
                        }
                        db = Math.min(Math.max(db, 50), 100);
                    }
                } else if (resultArray_count >= 9 && resultArray_count < 13) {
                    if (resultArray[8] > 50) {
                        db = resultArray[8] + 10;
                        if (db >= 100) { //If db reaches 100dB and no response, the db will start from 50dB in the next freqency.
                            db = 50;
                        }
                        db = Math.min(Math.max(db, 50), 100);
                    }
                } else if (resultArray_count >= 13 && resultArray_count < 17) {
                    if (resultArray[12] > 50) {
                        db = resultArray[12] + 10;
                        if (db >= 100) { //If db reaches 100dB and no response, the db will start from 50dB in the next freqency.
                            db = 50;
                        }
                        db = Math.min(Math.max(db, 50), 100);
                    }
                }

                db = await warmUp(hzList[i], db, calib, compensation, side, params, maxDb[i]);
                db = await mainTest(hzList[i], db, calib, compensation, side, params, params.rounds, i + j * 8, maxDb[i]);
            }

            resultArray[i + j * 8] = db;
            //currentIntensityRef.current = db
            //setCurrentIntensity(db);
            //db = currentIntensity;


            if (hzList[i] !== 1000) {
                ctrArray[i + j * 8] = retest_ctr;
            }

            // Added
            console.log("Tulokset: " + hzList[i] + " Hz " + db + "db");
            isResumingRef.current = false; // Reset resume flag after resuming

            // If second test of 1000 hz differs over 5db from first tests starts from beginning. Here are 5 patterns.
            // In clinical setting 1000Hz is tested twice
            if (i === 4 && params.restart === 1 && !restarted) {
                if (!(resultArray[i + j * 8] < resultArray[j * 8] + 10 && resultArray[i + j * 8] > resultArray[j * 8] - 10) && retest_ctr < 3) {
                    if (retest_ctr === 1) {
                        oneKTh1 = resultArray[j * 8];
                        oneKTh2 = resultArray[i + j * 8];
                        i = 0;
                        resultArray[j * 8] = resultArray[i + j * 8];
                        ctrArray[j * 8] = ctrArray[i + j * 8];
                        retest_ctr += 1;
                    } else if (retest_ctr === 2) {
                        oneKTh3 = resultArray[i + j * 8];
                        if ((Math.abs(oneKTh2 - oneKTh3) < 10) || (Math.abs(oneKTh1 - oneKTh3)) < 10) {
                            oneKTh = oneKTh3;
                            if (side === 1) {
                                test_accR = 0;
                            } else {
                                test_accL = 0;
                            }
                            restarted = true;
                            retest_ctr += 1;
                        } else {
                            oneKTh = median(oneKTh1, oneKTh2, oneKTh3);
                            resultArray[j * 8] = oneKTh;
                            if (side === 1) {
                                test_accR = 1;
                            } else {
                                test_accL = 1;
                            }
                            restarted = true;
                            retest_ctr += 1;
                        }
                    }
                } else { //A gap between Th1 and Th2 is less than 10dB in 1K. The loop ends. 
                    oneKTh1 = resultArray[j * 8];
                    oneKTh2 = resultArray[i + j * 8];
                    console.log("5. The diffrence is less than 10dB: TH1 in 1K Hz: "+oneKTh1+", TH2 in 1K Hz: "+oneKTh2);
                    restarted = true;
                    resultArray[j * 8] = resultArray[i + j * 8];
                    ctrArray[j * 8] = ctrArray[i + j * 8];
                    if (side === 1) {
                        test_accR = 0;
                    } else {
                        test_accL = 0;
                    }
                    retest_ctr += 1;
                }
            }
        }

        oneKHz_ctr = 1;
        total_step_ctrR += total_warmUp_ctrR + total_main_ctrR;
        total_step_ctrL += total_warmUp_ctrL + total_main_ctrL;
        console.log("Examination: 1. side check: "+side);

        currentEarIndexRef.current++;

        //setCurrentEarIndex(prevEarIndex => prevEarIndex + 1);
        //console.log("Ear index in line 642", currentEarIndex)
        console.log("current ear index:", currentEarIndexRef.current)
        console.log("Examination: 1. side check: " + side);

        
        //currentEarIndex++;
        setCurrentFrequencyIndex(0);
        console.log("line 647 current frequency index at the end of test", currentFrequencyIndex)
    }

    if (!interruptRef.current) {
        endTime = new Date();
        console.log("WHEN STOPPING AM I CALLED line 647")
        console.log("WHEN STOPPING AM I CALLED, the hzzzzz in line 650")
        sessionStorage.setItem('resultArray', resultArray);
        sessionStorage.setItem('mode', params.mode);
        results(resultArray, params.mode, hzList);
    }

    //isExaminationRunningRef.current = false; // Ensure the test is stopped

    if (currentEarIndexRef.current  >= earList.length) {
        console.log("Test completed for both ears.");
        endTime = new Date();
        sessionStorage.setItem('resultArray', resultArray);
        sessionStorage.setItem('mode', params.mode);
        results(resultArray, params.mode, hzList);
    }
}


// Testing consists of 2 parts, the warmUp and the mainTest. Warmup is used to quickly find the frequency the patient can hear. 
// 15 dB up or down. 
async function warmUp(hz, db, calib, compensation, side, param, maxDb) {
    let warmUp_ctr = 0; // Added 
    heardRef.current = false;
    HeardOnceRef.current = (false);

    

    if (testPhaseRef.current >= 1) {
        console.log(" db TestPhase exceeded 1, not entering warmUP")
        return }

    //db = currentIntensityRef.current 
    console.log("warmUpTest dB value: " + db);
    while (db > 0) {
        if (interruptRef.current) {
            console.log("warmUp: Test paused, exiting warmUp 1");
            break; // Exit loop if test is paused
        }
        console.log("db value, warmUpTest loop");

        if (interruptRef.current) { // Added to prevent 2 simultaneous runs if continued too soon after a pause
            console.log("warmUpTest: Test paused, exiting warmUp 2");
            break; // Exit loop if test is paused
        }

        testPhaseRef.current = 0
        heardRef.current = await soundTest(hz, db, calib, compensation, side, param, testPhaseRef.current, 1);

        // If patinet presses the button i heard a sound.
        if (heardRef.current) {
            HeardOnceRef.current = true;
            db = Math.max(-10, db - 15);
            warmUp_ctr +=1;
        }
        else {
            if (HeardOnceRef.current) {
                db = Math.max(db, -10);
                warmUp_ctr +=1;
                break;
            }
            db += 15;
            if (db > maxDb) {
                db = db - 15;
                warmUp_ctr +=1;
                break;
            }
        }
    }
    // Added
    if (side===1){
        total_warmUp_ctrR +=warmUp_ctr;
    } else{
        total_warmUp_ctrL +=warmUp_ctr; 
    }
    // Added
    return new Promise(resolve => { resolve(db) });
    }

// Main test for testing intensity level on a given frequency.
async function mainTest(hz, db, calib, compensation, side, param, rounds, index, maxDb) {
    let TH = [9999, 9998, 9997, 9996, 9995]; // Added 9996 and 9995
    let iThr = 0;
    let roundCounter = 0;
    let startdb = db;
    let heardArray = [];

    heardRef.current = false;
    let mainTest_counter = 0; // How many steps are taken
    let satadB_counter = 0; // Check a test in 100dB 
    db = currentIntensityRef.current 
    console.log("mainTest dB value: "+db);

    // Loop the testing procedure until same level is heard three times in the clinical setting. When a sound is heard, drop 10 dB and if not heard rise the dB level by 5dB
    while (true) {
        if (interruptRef.current) {
            console.log("mainTest: Test paused, exiting mainTest");
            break; // Exit loop if test is paused
        }

        while (heardRef.current && db > -10) {

            console.log("Did we go here")
            db = Math.max(db - 10, -10);
            // Dispaly the result in the screen
            if (db > -5) { //Does not play -10db, test continues with -5db.

                if (interruptRef.current) { // Added to prevent 2 simultaneous runs if continued too soon after a pause
                    console.log("warmUpTest: Test paused, exiting warmUp 2");
                    break; // Exit loop if test is paused
                }

                testPhaseRef.current = 2
                heardRef.current = await soundTest(hz, db, calib, compensation, side, param, testPhaseRef.current, roundCounter + 1);
                mainTest_counter += 1; //A counter is added
            }
            else {
                db = -10;
            }
        }
        db = Math.min(maxDb-maxDb%5, db + 5);

        if (interruptRef.current) { // Added to prevent 2 simultaneous runs if continued too soon after a pause
            console.log("warmUpTest: Test paused, exiting warmUp 2");
            break; // Exit loop if test is paused
        }

        testPhaseRef.current = 1
        heardRef.current = await soundTest(hz, db, calib, compensation, side, param, testPhaseRef.current, roundCounter + 1);

        if (interruptRef.current) {
            console.log("Test paused, exiting mainTest after soundTest");
            break; // Check again after async call
        }
                
        console.log("MAINTEST", heardRef.current)
        if (heardRef.current) { //Sound is heard
            heardArray.push(db);
            console.log("heardArray:"+heardArray);
            TH[iThr] = db;
            console.log("mainTest thresholds:"+TH);
            console.log("mainTest TH[4]: "+TH[4]);
            //if (TH[0] === TH[1] || TH[1] === TH[2] || TH[0] === TH[2]) {
            if ((TH[0] === TH[1] && TH[1] === TH[2]) ||
                (TH[0] === TH[1] && TH[1] === TH[3]) ||
                (TH[0] === TH[1] && TH[1] === TH[4]) ||
                (TH[0] === TH[2] && TH[2] === TH[3]) ||
                (TH[0] === TH[2] && TH[2] === TH[4]) ||  
                (TH[0] === TH[3] && TH[3] === TH[4]) ||
                (TH[1] === TH[2] && TH[2] === TH[3]) ||
                (TH[1] === TH[2] && TH[2] === TH[4]) ||
                (TH[1] === TH[3] && TH[3] === TH[4]) ||
                (TH[2] === TH[3] && TH[3] === TH[4])) {    
                console.log("Three numbers, which a patient responsed are the same out of five");
                break;
            //} else if (TH[2] != 9997) {
            } else if (TH[4] !== 9995) {    
                console.log("Game over!");
                roundCounter++;
                if (roundCounter >= rounds) {  
                    console.log("Ei saatu kunnon lopputulosta laitetaan korkein kuultu\n" + heardArray) // "We did onot get reliable measurement of the hearing result"
                    db = Math.max(...heardArray);
                    uncertain[index] = 1;
                    valid_data = 1; //If a patient failed to responde 3 same thresholds, the data becomes unreliable.
                    console.log("mainTest: unreliable data in 100dB " + uncertain);
                    break;
                }
                TH = [9999, 9998, 9997, 9996, 9995]; //Added 9996 && 9995
                db = startdb + 20; //Test starts from +10db next round
                iThr = 0;
                mainTest_counter += 1; //A counter is added
                console.log("mainTest rouncCounter: " + roundCounter);
                continue;
            }
            console.log("mainTest responses1: " + TH);
            iThr++;
        }
        else if (db >= maxDb-maxDb%5) { //Sound is not heard or no answer. Max level was reached and moving to next frwqucney
            console.log("mainTest not heard in 100dB: "+db);
            // This is added if clause, if some threshold values contained in TH, a test in 100 dB can be no response.  
            if ((TH[0] === 9999) && (TH[1] === 9998) && (TH[2] === 9997) && (TH[3] === 9996) && (TH[4] === 9995)){
                console.log("A test goes to the next frequency");
                // if no response in 100dB, it is unreliable. 
                uncertain[index] = 1;
                valid_data = 1; 
                break; // if no previous response in TH, a test goes to the next frequency.
            } else { // if there are responses in TH, a test continues in the same frequency.
                if (satadB_counter < 2) { //Allowing 2-time ignorance in 100dB
                    console.log("A test continues in the same frequency for only two times.");
                    satadB_counter ++;
                    continue;
                } else{
                    break;
                }
            }
        }
        //mainTest_counter += 1; //A counter is added
        console.log("mainTest round: "+rounds);
        //console.log("mainTest mainTest_counter: "+mainTest_counter);
    }
    // Added
    if (side===1){
        total_main_ctrR+=mainTest_counter;
    } else {
        total_main_ctrL+=mainTest_counter;
    }
    // Added
    return new Promise(resolve => {
        resolve(db);
    });
}



// Added a function for creating a temporary Json file AAA
function interruptFlag() {
    console.log("Interruptflag: invalid data");

    // Ensure startTime is defined before using it
    if (!startTime) {
        console.error("startTime is not defined.");
        return;
    }
    
    var now = new Date();
    let testTime = parseInt((new Date().getTime() - startTime.getTime()) / 1000);
    let testSec = testTime % 60;
    let testMin = (testTime - testSec) / 60;

    interruptRef.current =  true; // Pause the testsetInterrupt(true);  // A test is interrupted. 

    if (currentEarIndex === 0) { // Result is inaccurate.
        test_accR = 1; // right ear
        test_accL = 1;
    } else {
        test_accL = 1; // left ear
    }
    //test_acc = 1; // Inaccurate data

    valid_data = 1; // the data is interrupted and terminated by a patient. Meaning invalid.
    testInfoData = {
            "Testiaika": (testMin + ' min, ' + testSec + ' s'),
            "Keskeytetty": interrupt,
            "AccuracyR": test_accR, //Added: 0: accurate, 1: inaccurate in Right
            "AccuracyL": test_accL, //Added: 0: accurate, 1: inaccurate in Left
            "Validity": valid_data, //0: completed dataset, 1: incompleted dataset (Probably patient cancelled a test)
            "totalWarmUpStepsR": total_warmUp_ctrR, //Added: the number of warm-up steps on right to find thresholds
            "totalWarmUpStepsL": total_warmUp_ctrL, //Added: the number of warm_up steps to on left find thresholds
            "totalMainStepsR": total_main_ctrR, //Added: the number of main test steps on right to find thresholds
            "totalMainStepsL": total_main_ctrL //Added: the number of main test steps on left to find thresholds
        }
    json.testInfo.push(testInfoData);
    // Generate filename based on the current date and time
    var filename = "Invalid_" + now.getHours() + "." + now.getMinutes() + "_" + now.getDate() + "." + (now.getMonth() + 1) + "." + now.getFullYear() + ".json";

    // Convert JSON object to a string
    //var jsonString = JSON.stringify(json);

    // AJAX request to Flask server
    fetch('/save_audiometry_results', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            filename: filename,
            data: json
        })
    })
  interruptRef.current =  false; // Pause the testsetInterrupt(false);
}
// Added a function for creating a temporary Json file

async function soundTest(hz, db, calib, compensation, side, param, testPhase, roundCounter) {

    if (interruptRef.current) {
        console.log("soundTest interrupt inside if interrupt", { interrupt: interruptRef.current });
        pauseTest(db);
        endTime = new Date();
        results("Results array", resultArray, param.mode, [1000, 2000, 4000, 8000, 1000, 500, 250, 125]);
        return;
        }

    calib = getCalibrationFactor(side, hz, db);
    const headphoneModel = param.headphoneModel || 'defaultModel';
    compensation = await getCompensationFactor(headphoneModel, hz);
    console.log("Calibration:", calib, "compensation", compensation)


    playWave(hz, db, calib, compensation, side);
    
    let occurrence = (hz === 1000) ? oneKHz_ctr : retest_ctr; //Added a counter for test occurence
    let num = parseInt(Math.random() * 2000)
    console.log("Print out params in SoundTest Function", param)
    TotalTime = param.ResponseTime + num;

    return new Promise(resolve => {
        console.log("Promise inside soundTest got called to save results to soundData")
        setTimeout(() => {

            if (!pressStartTimeRef.current) {
                responseDuration = "NA";
            } else if ((pressStartTimeRef.current) && (buttonPressCount >= maxBtnPress)) {
                responseDuration = "NA";
            } else {
                responseDuration = Math.abs(RefSoundStartTime.current.current - pressStartTimeRef.current);
            }   

            if (interruptRef.current) {
                console.log("soundTest interrupt inside if interrupt", { interrupt: interruptRef.current });
                pauseTest(db);
                endTime = new Date();
                results("Results array", resultArray, param.mode, [1000, 2000, 4000, 8000, 1000, 500, 250, 125]);
                return;
                }

            console.log("soundTest: pressStartTime: " + pressStartTimeRef.current);
            console.log("soundTest: soundStartTime current: " + RefSoundStartTime.current.current);
            console.log("soundTest: soundStartTime previous: " + RefSoundStartTime.current.previous);
            let responseDurationInSeconds = responseDuration / 1000;
            console.log("soundTest: Response duration in seconds: " + responseDurationInSeconds);
            console.log("soundTest: Response duration in seconds current: " + responseDurationInSeconds);
            
            var soundData 
            soundData = {  //using global
                "Hz": hz,
                "dbLevel": db,
                "heard": pressedRef.current,
                "ear": side,
                "phase": testPhase,
                "round": roundCounter,
                "occurrence": occurrence, //Added test occurrence
                "responseDuration" : responseDurationInSeconds
            }

                console.log("printOut SoundData", soundData)
                console.log("line 931", { pressed: pressedRef.current }); // Use pressedRef here
                EnableContinueButtonRef.current = true
                if (!pressedRef.current) {
                    }
                json.sound.push(soundData);
                
                
                resolve(pressedRef.current);

            }, (TotalTime)); //Total Time given for response 5s + random 0-3 s
        pressStartTimeRef.current = (null)
        pressedRef.current = false;
    })
    
}

const NormalFrequencyArray = [1000, 2000, 4000, 8000, 1000, 500, 250, 125]
//const FullFrequencyArray = [1000, 2000, 3000, 4000, 6000, 8000, 1000, 750, 500, 250, 125]

function generateOrderArray(normalArray, testFrequencies) {

    if (!Array.isArray(testFrequencies)) {
    console.error("testFrequencies is not an array:", testFrequencies);
    return [];  // Return an empty array to avoid further errors
    }

    let orderArray = [];
    for (let freq of testFrequencies) {
        // Find the first occurrence of the frequency in the normal array
        let index = normalArray.indexOf(freq);
        if (index !== -1) {
            // Add the index to the order array
            orderArray.push(index);
        }
    }
    return orderArray;
}


function results(resultArray, mode, hzList, param, hz) {
    //var hzList = [1000, 2000, 4000, 8000, 1000, 500, 250, 125];
    console.log('Hz List in results read:', hzList);
    console.log({ interrupt: interruptRef.current });

    // Ensure we only send the results if the test was not interrupted
    if (!interruptRef.current) {
    interruptRef.current = false; // Ensure test is not marked as interrupted
    let testTime = parseInt((endTime.getTime() - startTime.getTime()) / 1000);
    let testSec = testTime % 60;
    let testMin = (testTime - testSec) / 60;

    const testStartTimestamp = startTime;
    const testEndTimestamp = endTime;
    const testDuration = `${testMin} min, ${testSec} s`;
    const interrupted = interruptRef.current;  // Reflect the interrupt status
    const accuracyRight = test_accR; // Accuracy in the right ear
    const accuracyLeft = test_accL;  // Accuracy in the left ear
    const validity = valid_data; // Validity of the test

    const totalWarmUpStepsRight = total_warmUp_ctrR;
    const totalWarmUpStepsLeft = total_warmUp_ctrL;
    const totalMainStepsRight = total_main_ctrR;
    const totalMainStepsLeft = total_main_ctrL;

    // Constructing result data based on the test mode (both ears or one ear)
    let results = [];

    if (mode === 0) {  // Both ears tested
        let orderArray = generateOrderArray(NormalFrequencyArray, hzList);
        let doubleOrderArray = [];

        for (let index of orderArray) {
        doubleOrderArray.push(index);  // Right ear
        doubleOrderArray.push(index + 8);  // Left ear
        }

        orderArray = doubleOrderArray;

        for (let i = 0; i < orderArray.length; i++) {
        let orderIndex = orderArray[i];
        let hzIndex = orderIndex % NormalFrequencyArray.length;
        let frequency = NormalFrequencyArray[hzIndex];
        let korva = orderIndex < NormalFrequencyArray.length ? 1 : -1;  // Right or Left ear

            // Map Korva to "right" or "left"
            let ear = korva === 1 ? "right" : "left";

        let resultData = {
            Hz: frequency,
            dbLevel: resultArray[orderIndex],
            ear: ear,
            occurrence: ctrArray[orderArray[i]],
            uncertain: uncertain[orderIndex],
        };
        results.push(resultData);
        }
    } else {  // Only one ear tested
        let orderArray = generateOrderArray(NormalFrequencyArray, hzList);
        for (let i = 0; i < orderArray.length; i++) {
        let orderIndex = orderArray[i];
        let hzIndex = orderIndex % NormalFrequencyArray.length;
        let frequency = NormalFrequencyArray[hzIndex];
        let ear = orderIndex < NormalFrequencyArray.length ? 1 : -1;
        let resultData = {
            Hz: frequency,
            dbLevel: resultArray[orderIndex],
            ear: ear,
            occurrence: ctrArray[orderArray[i]],
            uncertain: uncertain[orderIndex],
        };
        results.push(resultData);
        }
    }

    //Retrieve the sounddata that contains the path to the end results. Meaning we store the db  levels to achieve the end result.
    const fullResults = json.sound; 
    console.log(fullResults)

    // Build the data object to send
    const dataToSend = {
         // Ensure this ID is being properly populated
        testStartTimestamp,
        testEndTimestamp,
        testDuration,
        interrupted,
        accuracyRight,
        accuracyLeft,
        validity,
        totalWarmUpStepsRight,
        totalWarmUpStepsLeft,
        totalMainStepsRight,
        totalMainStepsLeft,
        results,
        fullResults
        };
    
        console.log("Data to send:", dataToSend);
    
        // Now send the data using fetch
        fetch(process.env.REACT_APP_BACKEND_URL + "/audiogram", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,  // Assuming you're using JWT for auth
            },
            body: JSON.stringify(dataToSend)
        })
            .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            return response.json();
            })
            .then(data => {
            if (data.status) {
                console.log('Audiogram saved successfully');
                // You can redirect or update the UI here
                finishTest()
            } else {
                console.error('Error saving audiogram:', data.message);
            }
            })
            .catch(error => {
            console.error('Error sending data:', error);
            });
        } else {
        console.log("Test was interrupted, results not processed.");
        }
    }
    



// dEFINE THE PROPERTIES OF THE SOUND BEING PLAYED
function playWave(hz, db, calib, compensation, side, duration= 1.5) {
    if (audioContext.state === 'suspended') {
        audioContext.resume();
    }

    // Stop any currently playing sound
    if (currentSrc) {
        currentSrc.stop();
        currentSrc.disconnect();
        currentSrc = null; // Reset to ensure the sound stops
    }

    let oscillator = audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(hz, audioContext.currentTime);

    let gainNode = audioContext.createGain();
    let dbMultiplier = (10 ** ((db + calib + compensation - 80) / 20));

    if (!isFinite(dbMultiplier)) {
        console.error(`Invalid gain value computed: ${dbMultiplier}`);
        dbMultiplier = 0; // Fallback to 0
    }

    console.log("dB value",db); // SHow the dB in the browser console
    sum = db+calib+compensation
    console.log("dB value korjattu",sum); // SHow the dB in the browser console

    gainNode.gain.value = dbMultiplier;
    console.log("gainNode value", gainNode.gain.value );

    //Measure time taken to do the test
    let currentTime = new Date(); //Tämä on ajan testausta varten
    let elapsedTime = currentTime - startTime;
    // Convert milliseconds into minutes, seconds, and milliseconds
    let seconds = Math.floor(elapsedTime / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    console.log("Freq: ", hz, + "dB: ", db, " volume: ", gainNode.gain.value , "Time:",  minutes + "min " + seconds + "s " );
    currentIntensityRef.current = db; // ... current intensity

    // Play the side either from the right or left side using the panner
    let panner = audioContext.createStereoPanner();
    panner.pan.setValueAtTime(side, audioContext.currentTime);

    oscillator.connect(gainNode);
    gainNode.connect(panner);
    panner.connect(audioContext.destination);

    RefSoundStartTime.current.previous = RefSoundStartTime.current.current;

    // Set the new current timestamp
    RefSoundStartTime.current.current = Date.now();
    console.log("Current time:", RefSoundStartTime.current.current);
    console.log("Previous time:", RefSoundStartTime.current.previous);

    oscillator.start();
    oscillator.stop(audioContext.currentTime + duration);
    currentSrc = oscillator; // Save the source to stop it later
}



return (
    <div className="h-screen bg-gray-100 overflow-y-auto flex flex-col justify-center items-center relative">
        <div className="inline-flex flex-col items-center gap-8 text-center w-full">
            {/* Header Section */}
            <div className="flex flex-col items-center gap-6 w-full px-4">
                <div className="text-center w-full">
                    <div className="font-caption-large text-digikuulo-colors-gray-700">
                    KUULOKYNNYSTESTI
                    </div>
                    <h1 className="justify-center items-center font-display-lg-bold font-[number:var(--display-lg-bold-font-weight)] text-digikuulo-colors-gray-900 text-[length:var(--display-lg-bold-font-size)] text-center tracking-[var(--display-lg-bold-letter-spacing)] leading-[var(--display-lg-bold-line-height)] [font-style:var(--display-lg-bold-font-style)] ">
                    Paina nappia, kun kuulet äänen
                    </h1>
                </div>
            </div>
    
        {/* Circle with Rotating Bar */}
        <div className="relative inline-flex items-center justify-center ">
            <div
                    className="rounded-full border-[50px] border-gray-100 border-solid w-[200px] h-[200px]"
                    style={{ backgroundColor: lightColor }}
                />
                <svg
                        className="absolute top-0 left-0 w-full h-full"
                        style={{ transform: 'rotate(-90deg)' }} // Rotate to start from the top
                    >
                        <circle
                        className="text-purple-500 animate-progress"
                        stroke="currentColor"
                        strokeWidth="10"
                        fill="transparent"
                        r="85"
                        cx="100"
                        cy="100"
                        style={{
                            strokeDasharray: 534.07, // Circumference of the circle (2 * π * r)
                            strokeDashoffset: 534.07, // Start with the stroke hidden
                            //animation: 'progress 10s linear forwards',
                        }}
                        />
                </svg>
    </div>
    
            {/* Button to toggle test state */}
            <button
            onClick={handleClick}
            className="mt-6 bg-purple-600 text-gray-100 py-4 px-8 rounded-lg transition-colors duration-300 ease-in-out hover:bg-purple-800 text-xl disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={buttonDisabled}
            >
            {isExaminationRunningRef.current
                ? "Kuulin äänen"  // Show when test is running
                : isTestPaused
                ? "Jatka testiä"   // Show when the test is paused
                : "Aloita testi"} 
            </button>
    
            {/* NavigationBar component */}
            <div className="absolute center bottom-10">
                <NavigationBar
                    currentQuestionIndex={0} // Not needed here but required by the component
                    totalQuestions={1} // Not needed here but required by the component
                    onNext={handleNextClick} // Logic when "Seuraava" is clicked
                    testName = {'Kuulokynnys'}
                    isNextEnabled={isNextEnabled} // "Seuraava" visible only after test finishes
                    pauseTest={pauseTest} // Pass pauseTest function to NavigationBar
                    resumeTest={resumeTest} 
                />
            </div>
    
            {/* AbortPage Modal */}
            {isModalOpen && <AbortPage onClose={handleCloseAbortModal} />}
    
            {/* HelpPage Modal */}
            {isHelpModalOpen && <HelpPageModal onClose={handleCloseHelpModal} />}
        </div>
    </div>
    )
};


export default Audiogram2;