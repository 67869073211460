import React from 'react';

const ButtonUtil = (
    {
        className = `text-sm flex gap-x-1 bg-white px-4 py-2 rounded-md justify-center items-center box-border transition-colors duration-300 ease-in-out hover:bg-gray-400 hover:text-gray-500 text-[#344054] cursor-pointer`,
        handleClick, icon, text = 'Button Text' }) => {
    return (
        <div className={`${className}`} onClick={handleClick}>
            <img src={icon} className='size-4' alt='' />
            <button >{text}</button>
        </div>
    );
};

export default ButtonUtil;