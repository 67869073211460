import React from 'react';

const SettingsBox = () => {
  return (
    <div className="border border-gray-300 p-4 mb-4">
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold">Asetukset</p>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">?</button>
      </div>
      <div className="space-y-4">
        <div className="flex justify-between">
          <label>Vastausaika (ms)</label>
          <input className="border w-1/3" type="text" id="responseTime" />
        </div>
        <div className="flex justify-between">
          <label>1000 hz aloita alusta</label>
          <input type="checkbox" id="restart" />
        </div>
        <div className="flex justify-between">
          <label>Testausjärjestys 0 = O + V</label>
          <input className="border w-1/3" type="text" id="mode" />
        </div>
        <div className="flex justify-between">
          <label>Saman dB tason testaus/Taajuus</label>
          <input className="border w-1/3" type="text" id="rounds" />
        </div>
        <div className="flex justify-between">
          <label>Kuulokemalli:</label>
          <select className="border w-1/3" id="headphoneModelSelect">
            {/* Add options */}
          </select>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button className="bg-blue-500 text-white px-4 py-2 rounded">Tallenna asetukset</button>
      </div>
    </div>
  );
};

export default SettingsBox;
