import React from 'react';

const CustomModal = ({ message, isOpen, onClose, onContinue }) => {
  if (!isOpen) return null;

  return (
    <div id="customModal" style={modalStyle}>
      <div id="customModalContent" style={modalContentStyle}>
        <p>{message}</p>
        <div style={buttonContainerStyle}>
          <button onClick={onClose} style={buttonStyle}>Testin valintaan</button>
          <button onClick={onContinue} style={buttonStyle}>Jatka</button>
        </div>
      </div>
    </div>
  );
};

// Styles for the modal
const modalStyle = {
  display: 'block',
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '10px',
  width: '300px',
  textAlign: 'center',
  margin: 'auto',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px',
};

const buttonStyle = {
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
};



export default CustomModal;
