import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./Modal2.css"

const AbortPage = ({ onClose }) => {
  const navigate = useNavigate();

  const handleContinue = () => {
    console.log("Continuing the test");
    onClose(); // Close the modal
  };

  const handleAbort = () => {
    console.log("Aborting test");
    navigate('/TestLandingPage'); // Redirect to home page or wherever you want after abort
  };

  return (
    <div className="sign-out-modal-overlay">
        <div className="sign-out-modal modal-content">
          {/* Abort Icon Display */}
            <p className="sign-out-modal-text"></p>
            <p className="sign-out-modal-text2 text-[#101828] font-bold text-2xl text-left">Mitä tällä sivulla tulisi lukea ja mitä toimintoja. Jatkaa testiä ohjeen lukemisen jälkeen tai kustsua apua?</p> <br></br>
            <p className="sign-out-modal-text2 text-[#1D2939] text-[22px] text-left">Testi jatkuu "Palaa testiin" painikkeen painamisen jälkeen</p> <br></br>
            <div className="sign-out-modal-actions flex justify-between gap-x-6 mt-2">
                <button onClick={handleContinue} className="flex justify-center items-center bg-white text-gray-500 border border-gray-300 py-3 px-2 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-300 gap-x-1 cursor-pointer flex-1">Palaa testiin</button>
                <button onClick={handleAbort} className="flex justify-center items-center bg-red-600 text-gray-100 border border-gray-300 py-3 px-3 rounded-lg transition-colors duration-300 ease-in-out hover:bg-red-800 gap-x-1 cursor-pointer flex-1">Keskeytä</button>
            </div>
        </div>
    </div>
  );
};

export default AbortPage;
