import { createSlice } from "@reduxjs/toolkit";

export const dinSlice = createSlice({
    name: "din",
    initialState: {
        headPhoneLeftEarSetting: 0, //DB
        headPhoneRightEarSetting: 0, //DB
        speakerLeftEarSetting: 0, //DB
        speakerRightEarSetting: 0, //DB
        adaptiveSetting: 2,
        muutumatonSetting: 1,
        earsSetting: ['right'],
        numberSeriesSetting: 'list1',
        speechVolumeSetting: 65,//DB
        soundSourceSetting: 'headPhone',
        leftEarCalibrationSetting: 0,
        rightEarCalibrationSetting: 0,
        stereoSetting:false,
    },

    reducers: {
        setHeadPhoneLeftEarSetting: (state, action) => {
            state.headPhoneLeftEarSetting = action.payload;
        },

        setHeadPhoneRightEarSetting: (state, action) => {
            state.headPhoneRightEarSetting = action.payload;
        },

        setSpeakerRightEarSetting: (state, action) => {
            state.speakerRightEarSetting = action.payload;
        },

        setSpeakerLeftEarSetting: (state, action) => {
            state.speakerLeftEarSetting = action.payload;
        },

        setAdaptiveSetting: (state, action) => {
            state.adaptiveSetting = action.payload;
        },

        setMuutumatonSetting: (state, action) => {
            state.muutumatonSetting = action.payload;
        },

        setEarsSetting: (state, action) => {
            state.earsSetting = action.payload;
        },

        setSpeechVolumeSetting: (state, action) => {
            state.speechVolumeSetting = action.payload;
        },

        setNumberSeriesSetting: (state, action) => {
            state.numberSeriesSetting = action.payload;
        },
        setSoundSourceSetting: (state, action) => {
            state.soundSourceSetting = action.payload;
        },

        setLeftEarCalibrationSetting: (state, action) => {
            state.leftEarCalibrationSetting = action.payload;
        },

        setRightEarCalibrationSetting: (state, action) => {
            state.rightEarCalibrationSetting = action.payload;
        },

        setStereoSetting:(state,action)=>{
            state.stereoSetting=action.payload
        }

    },
});

// Action creators are generated for each case reducer function
export const {
    setHeadPhoneLeftEarSetting,
    setHeadPhoneRightEarSetting,
    setAdaptiveSetting,
    setMuutumatonSetting,
    setEarsSetting,
    setSpeechVolumeSetting,
    setNumberSeriesSetting,
    setSpeakerLeftEarSetting,
    setSpeakerRightEarSetting,
    setSoundSourceSetting,
    setLeftEarCalibrationSetting,
    setRightEarCalibrationSetting,
    setStereoSetting,
} = dinSlice.actions;