// App.js
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ProtectedRoute from './Components/Routes/ProtectedRoute';
import RoleBasedRoute from './Components/Routes/RoleBasedRoute';
import PageZero from './Components/UI/PageZero';
import LandingPage from './Components/UI/LandingPage/LandingPage';
import LoginPage from './Components/UI/LoginPage/LoginPage';
import EditLoginPage from './Components/UI/LoginPage/EditLoginPage';
import UserDetailsPage from './Components/UI/UserDetailsPage/UserDetailsPage';
import TestLandingPage from './Components/MEDICAL/TestPage/TestLandingPage';
import Audiogram from './Components/MEDICAL/Audiogram/Audiogram';
import Audiogram2 from './Components/MEDICAL/Audiogram/Audiogram_v2';
import AudiogramCalibration from './Components/MEDICAL/Calibration/AudiogramCalibration/AudiogramCalibrationMain';
import AudiogramHeadphoneCompensation from './Components/MEDICAL/Calibration/AudiogramCalibration/AudiogramHeadphoneCompensation';
import KuuloKysely from './Components/MEDICAL/PROM/KuuloKysely';
import KuuloKyselyLandingPage from './Components/MEDICAL/PROM/KuuloKyselyLandingPage'
import AudiogramLandingPage from './Components/MEDICAL/Audiogram/AudiogramLandingPage'


import QuestionnaireAUD from './Components/MEDICAL/UsabilityTests/QuestionnaireAUD'
import QuestionnaireDIN from './Components/MEDICAL/UsabilityTests/QuestionnaireDIN'
import QuestionnairePROM from './Components/MEDICAL/UsabilityTests/QuestionnairePROM'
import QuestionnaireFINISH from './Components/MEDICAL/UsabilityTests/QuestionnaireFINISH'


import SettingsPage from './Components/MEDICAL/Settingspage/SettingsPage';
import isElectron from './Functions/isElectron';
import KuulokkeidenTestaaminen from './Components/MEDICAL/KuulokkeidenTestaaminen/KuulokkeidenTestaaminen';
import FullPageLoader from './utils/Loader/FullPageLoader';
import HalyPuheTesti from './Components/MEDICAL/DIN/HalyPuheTesti';

import DinTestMainv4 from './Components/MEDICAL/DIN/DinTestMainv4';
import { toast, ToastContainer } from 'react-toastify';
import { Lock_Icon } from './assets/Icons';
import FullPageModalWrapperKiosk from './Components/Modals/ModalWrapper/FullPageModalWrapperKiosk';
import HeadPhoneCalibrationPage from './Components/MEDICAL/Calibration/DinCalibration/HeadPhoneCalibrationPage';
import SpeakerCalibrationPage from './Components/MEDICAL/Calibration/DinCalibration/SpeakerCalibrationPage';



const App = () => {
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const tokenFromStorage = localStorage.getItem('token');
  const token = useSelector((state) => state.authState.token) || (tokenFromStorage && isValidJSON(tokenFromStorage) ? JSON.parse(tokenFromStorage) : null);

  /* For Handling Kiosk Mode */
  const [kiosModeRequestState, setKiosModeRequestState] = useState(false);
  const [kiosModeOpenRequestState, setKiosModeOpenRequestState] = useState(false);
  const [kiosModeExitRequestState, setKiosModeExitRequestState] = useState(false);
  const [kioskMessage, setKioskMessage] = useState('');

  const [isClosing, setIsClosing] = useState(false);

  console.log({ kiosModeRequestState, isClosing });

  // Set default login credentials (preset for demo)
  const [loginInformationStaff, setLoginInformationStaff] = useState({
    userName: '',    // Default username
    password: ''  // Default password
  });

  // Helper function to check if the value is valid JSON
  function isValidJSON(value) {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  }

  const closeKioskModel = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setKiosModeRequestState(false);
    }, 300); // Allow closing animation to complete
  };

  // Handle login process
  const handleLogin = async () => {
    try {
      const { userName, password } = loginInformationStaff;
      if (!userName || !password) {
        toast.error('All fields are required', { position: 'top-right' });
        return;
      }

      const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/login`, {
        userName: userName,
        password: password
      });

      if (data.status === false) {
        throw new Error(data.message);
      }

      toast.success("Login successful");
      /* For Opening the kiosk mode */

      if (isElectron() && kiosModeOpenRequestState === true && kiosModeExitRequestState === false) {
        const response = await window.electronapi.invokeKioskMode({ status: true });
        console.log(`[+] Invoke kiosk mode request open:`, response);
      }

      /* For Closing the kiosk mode */
      if (isElectron() && kiosModeOpenRequestState === false && kiosModeExitRequestState === true) {
        const response = await window.electronapi.invokeKioskMode({ status: false });
        console.log(`[+] Invoke kiosk mode request exit:`, response);
      }
      /* Close the kiosk modal */
      closeKioskModel();

      // Store user and token in localStorage
      //localStorage.setItem('user', JSON.stringify(data.employee));
      //localStorage.setItem('token', data.token);
      // Redirect to the settings page after login

    } catch (error) {
      toast.error(`Login failed: ${error.message}`);
    }
  };


  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        if (!token) {
          setLoading(false);
          return;
        }
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/access/getUserRole`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        if (response.data && response.data.role) {
          setRole(response.data.role);
        } else {
          setRole(null);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
        setRole(null);
      } finally {
        /* remove this in production environment */
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    };
    fetchUserRole();
  }, [token]);

  /* This will handle message from the Main process to renderer process */
  const fromMainToRenderer = (message) => {
    console.log('[+] Message received from Main Process:', message);
    try {
      const { kiosModeOpenRequest, kiosModeExitRequest, data } = message;
      setKiosModeExitRequestState(kiosModeExitRequest);
      setKiosModeOpenRequestState(kiosModeOpenRequest);
      setKioskMessage(data);
      setKiosModeRequestState(true);
      return;
    } catch (error) {
      console.log(`[-] fromMainToRenderer Error:`, error.message);
    }
  };

  useEffect(() => {
    if (loading === false) {
      if (isElectron()) {
        console.log({ isElectron: isElectron() });
        window.electronapi.fromMainToRenderer(fromMainToRenderer);
      }
    }
    return () => {
      if (isElectron())
        window.electronapi.removefromMainToRenderer(fromMainToRenderer);
    };
  }, [loading, kiosModeRequestState]);


  if (loading) {
    return <FullPageLoader />; // Replace with a proper loading component
  }


  if (kiosModeRequestState === true) {
    return <FullPageModalWrapperKiosk isOpen={kiosModeRequestState} isClosing={isClosing}>
      <div className='h-screen bg-gray-50 flex items-center justify-center'>
        <div className='bg-white p-6 rounded-lg shadow-lg w-80 relative'>
          <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
            <ToastContainer />
            <div className='flex justify-center'>
              <img src={Lock_Icon} alt="Lock Icon" />
            </div>
            <h2 className='text-center text-xl text-[#101828] font-bold mt-4 mb-4'>TrueHear Kiosk Authentication</h2>
            {kioskMessage && <h2 className='text-center text-xl text-[#101828] font-bold mt-4 mb-4'>{kioskMessage}</h2>}

            <div className='mb-4'>
              <label className='block text-[#101828] font-bold'>Käyttäjänimi</label>
              <input
                type='text'
                name='userName'
                value={loginInformationStaff.userName}
                onChange={(e) => setLoginInformationStaff({ ...loginInformationStaff, userName: e.target.value })}
                className='w-full p-2 border rounded'
                placeholder='Enter your username'
              />
            </div>

            <div className='mb-4'>
              <label className='block text-[#101828] font-bold'>salasana</label>
              <input
                type='password'
                name='password'
                value={loginInformationStaff.password}
                onChange={(e) => setLoginInformationStaff({ ...loginInformationStaff, password: e.target.value })}
                className='w-full p-2 border rounded'
                placeholder='Enter your password'
              />
            </div>

            <div className='flex flex-col mt-1'>
              <button
                type='submit'
                className='w-full bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md'
              >
                Login
              </button>
            </div>
            <div className='flex flex-col mt-1'>

              <button
                type='button'
                className='w-full bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md'
                onClick={e => {
                  e.preventDefault();
                  closeKioskModel();
                }
                }
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div >
    </FullPageModalWrapperKiosk >;

  }

  return (
    <div className="App">
      <Routes>
        {/* Public Routes PageZero */}
        <Route path="/" element={<PageZero />} />
        <Route path="/LandingPage" element={<LandingPage />} />
        <Route path="/loginpage" element={<LoginPage />} />




        {/* Protected Routes */}

        {/* User Details Route */}
        <Route
          path="/UserDetailsPage"
          element={
            <ProtectedRoute>
              <UserDetailsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/EditLoginPage"
          element={
            <ProtectedRoute>
              <EditLoginPage />
            </ProtectedRoute>
          }
        />

        {/* Tests Page */}
        <Route
          path="/TestLandingPage"
          element={
            <ProtectedRoute>
              <TestLandingPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/AudiogramLandingPage"
          element={
            <ProtectedRoute>
              <AudiogramLandingPage />
            </ProtectedRoute>
          }
        />


        <Route
          path="/Audiogram"
          element={
            <ProtectedRoute>
              <Audiogram />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Audiogram2"
          element={
            <ProtectedRoute>
              <Audiogram2 />
            </ProtectedRoute>
          }
        />

        <Route
          path="/AudiogramCalibration"
          element={
            <ProtectedRoute>
              <AudiogramCalibration />
            </ProtectedRoute>
          }
        />

        <Route
          path="/AudiogramHeadphoneCompensation"
          element={
            <ProtectedRoute>
              <AudiogramHeadphoneCompensation />
            </ProtectedRoute>
          }
        />


        <Route
          path="/KuuloKysely"
          element={
            <ProtectedRoute>
              <KuuloKysely />
            </ProtectedRoute>
          }
        />

        <Route
          path="/KuulokyselyLandingPage"
          element={
            <ProtectedRoute>
              <KuuloKyselyLandingPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/QuestionnaireDIN"
          element={
            <ProtectedRoute>
              <QuestionnaireDIN />
            </ProtectedRoute>
          }
        />

        <Route
          path="/QuestionnairePROM"
          element={
            <ProtectedRoute>
              <QuestionnairePROM />
            </ProtectedRoute>
          }
        />

        <Route
          path="/QuestionnaireAUD"
          element={
            <ProtectedRoute>
              <QuestionnaireAUD />
            </ProtectedRoute>
          }
        />

        <Route
          path="/QuestionnaireFINISH"
          element={
            <ProtectedRoute>
              <QuestionnaireFINISH />
            </ProtectedRoute>
          }
        />








        {/* Din Page v1*/}

        <Route
          path="/HalyPuheTesti"
          element={
            <ProtectedRoute>
              <HalyPuheTesti />
            </ProtectedRoute>
          }
        />

        <Route
          path="/DINTestmainv4"
          element={
            <ProtectedRoute>
              <DinTestMainv4 />
            </ProtectedRoute>
          }
        />

        {/* Kuulokkeiden Test */}

        <Route path='/Kuulokkeiden'
          element={
            <ProtectedRoute>
              <KuulokkeidenTestaaminen />
            </ProtectedRoute>
          }
        />


        {/* Role-Based Access for Employee */}
        <Route
          path="/Settings"
          element={
            <ProtectedRoute>
              <RoleBasedRoute role={role} requiredRole="employee">
                <SettingsPage />
              </RoleBasedRoute>
            </ProtectedRoute>
          }
        />


        <Route
          path="/HeadPhoneCalibrationPage"
          element={
            <ProtectedRoute>
              <RoleBasedRoute role={role} requiredRole="employee">
                <HeadPhoneCalibrationPage />
              </RoleBasedRoute>
            </ProtectedRoute>
          }
        />

        <Route
          path="/SpeakerCalibrationPage"
          element={
            <ProtectedRoute>
              <RoleBasedRoute role={role} requiredRole="employee">
                <SpeakerCalibrationPage />
              </RoleBasedRoute>
            </ProtectedRoute>
          }
        />


        {/* Catch-All Redirect */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* <ToastContainer/> */}


    </div>
  );
};

export default App;
