import React from 'react';

const FrequencyDataBox = () => {
  return (
    <div className="border border-gray-300 p-4">
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold">Tallennetut tasot taajuuksittain</p>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">?</button>
      </div>
      <div className="space-y-2">
        <ul>
          <li>Frequency: 125Hz, dB Level: 50dB</li>
          {/* Add more frequency data dynamically */}
        </ul>
      </div>
    </div>
  );
};

export default FrequencyDataBox;
