// Change folder path at suync to main

import React, {useState, useEffect, useRef} from "react";
import { useNavigate } from 'react-router-dom';
import { PauseIcon, InfoIcon } from "../../../assets/Icons"
import { getParam, getCalibrationFactor, getCompensationFactor } from './fetchParam';
import { globalState, fetchData } from './fetchParam';
import  CustomModal from './AudiometryModal';
import "./Audiogram.css";
import "./tailwind.css";
import "./styleguide.css"; // Temporarily import here. remove when merged with main.


const Audiogram = () => {
  const [isExaminationRunning, setIsExaminationRunning] = useState(false);
  const [buttonPressCount, setButtonPressCount] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [keyHandled, setKeyHandled] = useState(false);
  const [lightColor, setLightColor] = useState("green");
  const [interrupt, setInterrupt]  = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [pressStartTime, setPressStartTime] = useState(null);
  const [pressDuration, setPressDuration] = useState(null);
  const [pressed, setPressed] = useState(false);
  const [currentFrequencyIndex, setCurrentFrequencyIndex] = useState(0);
  const [currentIntensity, setCurrentIntensity] = useState(50);
  const [currentEarIndex, setCurrentEarIndex] = useState(0);
  
  //Immediately change state
  const interruptRef = useRef(false);
  const pressedRef = useRef(pressed);
  const heardRef = useRef(false);
  const HeardOnceRef = useRef(false)
  const StartFlagRef = useRef(false);
  const isExaminationRunningRef = useRef(false)
  const isResumingRef = useRef(false)
  const currentEarIndexRef = useRef(0);

  
  const ipcRenderer = isElectron() ? window.require('electron').ipcRenderer : null;
  const navigate = useNavigate();
  var patient_ID = localStorage.getItem("getPatientID");
//var isAuthenticated = document.body.getAttribute('data-authenticated') === 'True';

var uncertain = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var startTime, endTime;

var resultArray = [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null];
var db; // Global declaration
var sum 
var maxDb

var clickTimer;
var testInfoData
var ctrArray =  []; //counter array
var retest_ctr = 1 // Added: retest counter (max 2)
var oneKHz_ctr = 1 // Added: the number of occurene of 1000Hz in test
var oneKTh1 = 0 //Threshold1 in 1000Hz
var oneKTh2 = 0 //Threshold2 in 1000Hz
var oneKTh3 = 0 //Threshold3 in 1000Hz
var oneKTh = 0 // Threshold value in 1000Hz
var test_accR = 0 //0: accurate data, 1: inaccurate data in right ear (ex. When a patient's responses in 1K Hz differ by more than 10 dB on three times.)
var test_accL=0 //0: accurate data, 1: inaccurate data in left ear (ex. When a patient's responses in 1K Hz differ by more than 10 dB on three times)
var valid_data = 0 //0: valid data, 1: invalid data (ex. When a patient discontinues a test midway.)
var total_warmUp_ctrR = 0 //Warm-up test counter on right ear for 1000Hz
var total_warmUp_ctrL = 0 //Warm-up test counter on left ear for 1000Hz
var total_main_ctrR = 0 //The number of main test steps for the right ear to find thresholds for each patient
var total_main_ctrL = 0 //The number of main test steps for the left ear to find thresholds for each patient
var total_step_ctrR = 0 //The total number of steps for the right ear
var total_step_ctrL = 0 //The total number of steps for the left ear

var json = {
    testInfo: [], // Array to store sound data    
    results: [],
    dbLimits: {},  
    sound: [] // Array to store test information
};
let soundStartTime = null;
let responseDuration = null; // = pressStartTime - soundStartTime
var maxBtnPress = 5; // The maximum number of button press allowed.
var TotalTime = null;

var audioContext = new (window.AudioContext || window.webkitAudioContext)(); // Create a single instance of AudioContext
var currentSrc; // Variable to keep track of the currently playing source

  
  function isElectron() {
    return typeof window !== 'undefined' && window.process && window.process.type === 'renderer';
  }


  function median(a, b, c) {
    const sorted = [a, b, c].sort((x, y) => x - y);  // Sort the integers
    const middle = Math.floor(sorted.length / 2);
    const isEven = sorted.length % 2 === 0;
    // Return the middle value or average of two middle values
    return isEven ? (sorted[middle - 1] + sorted[middle]) / 2 : sorted[middle];
  }


  async function fetchAndInitialize() {
    await fetchData(); // Ensure this function is awaited
    //const params = await initializeParams();
    //if (params) {
    //    console.log("Fetched and initialized parameters:", params);
    //}
  }


  async function initializeParams() {   // Fetch parameters at the start
    try {
        const params = getParam();
        if (params) {
            const { mode, ResponseTime, rounds, restart, headphoneModel, calibrationLevels, maxLevels, noticeLevels } = params;
            //console.log(`Headphone Model: ${headphoneModel}`);
            return params;
        }
    } catch (error) {
        console.error("Error initializing parameters:", error);
    }
    return null;
  }


  fetchAndInitialize()


  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);    // Add event listener on mount
    return () => {     // Clean up event listener on unmount
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);


  useEffect(() => {
    pressedRef.current = pressed;  // Sync the ref with the latest `pressed` value
  }, [pressed]);


  useEffect(() => {
    if (!globalState.settings || !globalState.calibration || !globalState.compensation) {
        fetchAndInitialize();
    }
}, []); // Empty dependency array to ensure it only runs once on mount



  const handleClick = () => {
    if (!isExaminationRunningRef.current) {
      setIsExaminationRunning(true);
      console.log("Call examination fucntion:");
      examination();
    } else {
      console.log("A voice was heard!:");
      pressTheButton();
    }
  };
  

  // Show the modal and pause the test
  const showModal = (message) => {
    interruptRef.current =  true; // Pause the test
    isExaminationRunningRef.current = false;
    setIsExaminationRunning(false); // Pause the examination
    setModalOpen(true); // Show the modal
    console.log("Test paused. Current test state saved.");
  };

  // Close the modal and continue the test
  const closeModal = () => {
    setModalOpen(false); // Show the modal
    interruptRef.current =  false; // Pause the test
    isExaminationRunningRef.current = false; //SHOULD THIS BE TRUE
    setIsExaminationRunning(true); // Resume the examination
    console.log("Test resumed.");
  };

  // Navigate to the previous page when the user wants to leave the test
  const navigateToPreviousPage = () => {
    setModalOpen(false); // Show the modal
    navigate('/TestLandingPage'); // Navigate to the previous page
  };


/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////

function handleExaminationReStart() {
  setTimeout(() => {
      if (!isExaminationRunningRef.current) {
          isExaminationRunningRef.current = false;
          console.log("handleExaminationRestart: Examination started."+ StartFlagRef.current);
          ContinueAfterRelease(); // Start the examination
      } else {
          //isExaminationRunning = false; // Ensure this is set to true to start the examination
          console.log("handleExaminationReStart: Examination is already running."+ StartFlagRef.current);
      }
  }, 1000); // Delay of 1000 milliseconds before the condition is checked
}


function handleKeyPress(event) {
  // Key code for 'Y' is 89 and for 'y' is 121
  if (!isExaminationRunningRef.current){
      console.log("handleKeyPress: Examiniation is NOT running");
      console.log("handleKeyPress: StartFlag2: "+ StartFlagRef.current);
      return;
  }
  console.log("handleKeyPress: Examiniation is running.");
  if (event.keyCode === 89 || event.keyCode === 121) {
      if (event.type === 'keydown') {
          if (!keyHandled) { // Check if the key press hasn't been handled yet
              setKeyHandled(true); // Set the flag to true indicating the key press is handled

              startTime = Date.now();
              setPressStartTime(startTime)  // Start timing the key press

              console.log("handleKeyPress: pressStartTime1 "+pressStartTime );

              // Light up the 'light' element immediately
              setLightColor("limegreen");
              //reactionBtn.classList.add('bg-blue-900');

              PauseWhilePressing();
          }
      } else if (event.type === 'keyup') {
          // Calculate the duration of the key press
          if (!pressStartTime) {
              setPressDuration(0);
          } else {
              pressDuration = Date.now() - pressStartTime;
              setPressDuration(pressDuration); // Update the state
          }
          interruptRef.current =  false; // Pause the testsetInterrupt(false);
          console.log("handleKeyPress: pressDuration: "+pressDuration);
          console.log("handleKeyPress: pressStartTime2: "+pressStartTime);
          // Reset the handled state on key up
          setKeyHandled(false);
          

          // Reset the 'light' element and button styles
          setLightColor("green");
          //reactionBtn.classList.remove('bg-blue-900');

          console.log("handleKeyPress: totalTime", TotalTime);
          var timeRemained = TotalTime- (new Date() - soundStartTime);
          console.log("handleKeyPress: timeRemained: "+timeRemained);
          if (timeRemained < 0) { //A button is pressed too late. A button press is ignored.
              console.log("handleKeyPress: a button is pressed too late. "+timeRemained);
              isExaminationRunningRef.current = false;
              handleExaminationReStart();            
          //if (pressDuration > param[1]) { //Have to match the total time so that the osund is not played twice
          } else if (pressDuration > TotalTime) { 
              // If key press is longer than 5 seconds
              alert("Please do not press a key for longer than 5 seconds.");
              console.log(pressDuration, "Key press was longer than 5 seconds, resetting examination.");

              isExaminationRunningRef.current = false;
              // Handle the button press logic here
              handleExaminationReStart();
          } else {
              // Normal behavior when the press is less than 5 seconds
              console.log("handleKeyPress: Normal behavior: pressDuration < TotalTime");
              //console.log("Normal behavior: pressDuration < param[1]");
              //handleExaminationReStart();
              pressTheButton(); // Handle the button press logic here
              ContinueAfterRelease(); // Continue the test after the press
          }
      }
  }
}


function PauseWhilePressing() {
  // Added checking whether a test is started or not
  console.log("PauseWhilePressing: StartFlag1: "+ StartFlagRef.current);
  if ( StartFlagRef.current === true){ //A test has already started.
      console.log("PauseWhilePressing: A test has already started and posed a test.");
      interruptRef.current =  true; // Pause the testsetInterrupt(true);
      isResumingRef.current = true;
      db = currentIntensity
  } else {                // A test has not started yet.
      console.log("PauseWhilePressing: A test has not started yet.");
      interruptRef.current =  false; // Pause the test setInterrupt(false);
      isResumingRef.current = false
      //reactionBtn.disabled = true;
  }    
  setCurrentFrequencyIndex(currentFrequencyIndex); //Removed
  setCurrentIntensity(db); // Added  
  console.log("PauseWhilePressing: Pausing at intensity: " + db  + "db");
  console.log("PauseWhilePressing: Pausing, currenTestState contents: " );
}

function ContinueAfterRelease() {
  console.log("ContinueAfterRelease: StartFlag1: "+ StartFlagRef.current);
  //document.getElementById('returnBtn').style.display = 'none';
  //document.getElementById('stopBtn').style.display = 'inline-block';
  interruptRef.current =  false; // Pause the testsetInterrupt(false);
  // Added checking when lopeta testi button is pressed before a test starts or in the middle of test
  if ( StartFlagRef.current === false) { //Initially starting from 50dB
      db = 50
  } else {
      console.log("ContinueAfterRelease: StartFlag is on: "+ StartFlagRef.current);
      db = currentIntensity;
      setCurrentEarIndex(currentEarIndex); // Added
      console.log("ContinueAfterRelease: Resuming at intensity: " + db + "dB");
      console.log("ContinueAfterRelease: Resuming, currenTestState contents: " );
      examination();
  }
}


// Button to pause the test
function pauseTest(db) {
  console.log("pauseTest: StartFlag1: "+ StartFlagRef.current);
  // Hide the stop button and show the return button when the test is paused
  //document.getElementById('stopBtn').style.display = 'none';
  //document.getElementById('returnBtn').style.display = 'inline-block';
  // Added checking whether a test is started or not
  if ( StartFlagRef.current === true){ //A test has already started.
      console.log("pauseTest: A test has already started and posed a test.");
      interruptRef.current =  true; // Pause the testsetInterrupt(true);
      isResumingRef.current = true
      db = currentIntensity
  } else {                // A test has not started yet.
      console.log("pauseTest: A test has not started yet.");
      interruptRef.current =  false; // Pause the testsetInterrupt(false);
      isResumingRef.current = false
  }    
  setCurrentFrequencyIndex(currentFrequencyIndex); //Removed
  setCurrentIntensity(db); // Added  
  console.log("pauseTest: Pausing at intensity: " + db  + "db");
  console.log("pauseTest: Pausing, currenTestState contents: ", currentFrequencyIndex);
}



// Button to resume the test
function resumeTest() {
  console.log("resumeTest: StartFlag1: " +  StartFlagRef.current);
  //document.getElementById('returnBtn').style.display = 'none';
  //document.getElementById('stopBtn').style.display = 'inline-block';
  setModalOpen(false); // Close the modal /// ADDED
  closeModal();
  interruptRef.current =  false; // Pause the testsetInterrupt(false);
  isExaminationRunningRef.current = false;
  setIsExaminationRunning(true); // Resume the examination
  // Added checking when lopeta testi button is pressed before a test starts or in the middle of test
  if ( StartFlagRef.current === false) { //Initially starting from 50dB
      db = 50
      console.log("resumeTest: Resuming, using 50 dB as start. ");
  } else {
      db = currentIntensity;
      setCurrentEarIndex(currentEarIndex); // Added
      console.log("resumeTest: Resuming at intensity: " + db + "dB");
      console.log("resumeTest: Resuming, currenTestState contents: ");
      //isExaminationRunningRef.current = false;
      examination();
  }
  
}



// Keep tarck of teh button status
const pressTheButton = () => {
  clearTimeout(clickTimer); // Clear previous timer

  if (buttonDisabled) return; // Exit if button is disabled

  setButtonPressCount(prevCount => prevCount + 1); // Increase press count
  console.log("pressTheButton: buttonPressCount", buttonPressCount + 1);

  if (buttonPressCount + 1>= maxBtnPress) {
      alert("Liian monta painallusta. Yksi painallus riittää. Jatkuva napin painaminen johtaa testin hylkäämiseen.");
      pressedRef.current = false; // Immediately update ref
      console.log("pressTheButton: too many times a button is pressed. buttonPressCount: "+buttonPressCount);
      setButtonPressCount(0); // After a message, buttonPressCount is initialized.
      setButtonDisabled(true); // Disable the button
      setTimeout(() => {
          setButtonDisabled(false); // Enable the button again
      }, 1000); // Changed from 500 to 1000

  } else {
      console.log("Pressed line 380", {pressed})
      //setPressed(true); // Update state
      pressedRef.current = true; // Immediately update ref
      console.log("Pressed line 382", {pressed})
      //var light = document.getElementById("light");  //Switch colour for a second
      //light.style.backgroundColor = light.style.backgroundColor === "green" ? "limegreen" : "green";
      setTimeout(() => {
        // Reset any visual changes after 2 seconds
      }, 2000);
      //let reactionBtn = document.querySelector('#reactionBtn');
      //Disable reaction button after pressing?
      //if (reactionBtn.disabled) return;
      // Disable the button
      //reactionBtn.disabled = true;
       // Re-enable the button after 3 seconds
      //setTimeout(() => {
      //    reactionBtn.disabled = false;
      //}, 2000);

      //reactionBtn.classList.remove('bg-blue-500');
      //reactionBtn.classList.add('bg-blue-900');

     // if (isAuthenticated) {
     //     drawResponseMark(currentFreq, currentIntensity, true); // Assuming these values are defined
     // }

      setTimeout(() => {
      //reactionBtn.classList.remove('bg-blue-900');
      //reactionBtn.classList.add('bg-blue-500');
      }, 750)
  } 
  // Reset button press count after 1 second of inactivity
  clickTimer = setTimeout(() => {
      setButtonPressCount(0);
      setPressStartTime(null);
      setPressDuration(null);
  }, TotalTime);
}













    ////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////

    // Define the examination function at the top level
 async function examination() {

  console.log("In examination", isExaminationRunningRef.current)

  if (isExaminationRunningRef.current) {
    console.log("Examination: Examination is already running or interrupted.");
    return;
    }

  const params = await initializeParams();
  if (!params) {
      console.error("Failed to initialize parameters.");
      return;
  }

  // Ensure compensation data is fetched
  const compensation = globalState.compensation;
  if (!compensation) {
      console.error("Compensation data not loaded.");
      return;
  }

    // Set the start time for the examination
  startTime = new Date(); // Add this line to initialize startTime
 // console.log("Parameters loaded in examination function:", params);

  var hzList = [1000, 2000, 4000, 8000, 1000, 500, 250, 125];
  const mode = params.mode;
  console.log(mode)
  const headphoneModel = params.headphoneModel || 'defaultModel';

  var earList;
  if (mode === 0) {
      earList = [1, -1]; // Test right ear first, then left ear
  } else if (mode === 1) {
      earList = [0]; // Test both ears at the same time
  } else if (mode === 2) {
      earList = [1]; // Test right ear only
  } else if (mode === 3) {
      earList = [-1]; // Test left ear only
  }

  console.log("earList: ", earList);

  if (!isResumingRef.current ) {
      startTime = new Date();
      setCurrentEarIndex(0);
      setCurrentFrequencyIndex(0);
  }

  await new Promise(resolve => setTimeout(resolve, 5000));
  isExaminationRunningRef.current = true;

  StartFlagRef.current = true;

  for (let j = currentEarIndexRef.current; j < earList.length; j++) {
    console.log("00. currentEarIndex: "+ currentEarIndex);
    console.log("00. currentEarIndex: "+ currentEarIndexRef.current);
    console.log("00. currentEarLength: "+ earList.length);
    currentEarIndexRef.current = j

    //j = currentEarIndex;

      let side = earList[j];
      let restarted = false;
      retest_ctr = 1;

      for (let i = currentFrequencyIndex; i < hzList.length; i++) {
          if (interruptRef.current) {
              currentEarIndexRef.current = j;
              console.log("IS this reached")
              //pauseTest(db); // Break out of the loop if the test is interrupted and save db value
              return;
          }
          console.log("savedtocurrentFrequencyIndex in examination",i)
          setCurrentFrequencyIndex(i);

          if (!isResumingRef.current  || j !== currentEarIndexRef.current || i !== currentFrequencyIndex) {
              db = 50; // Reset db to 50 for new frequency test
          }

          const hz = hzList[i];
          console.log("FrequenCY PRINted out here",hz)
          const calib = await getCalibrationFactor(side, hz, db);
          const compensation = await getCompensationFactor(headphoneModel, hz);

          console.log(`Calibration: ${calib}, Compensation: ${compensation}`);

          maxDb = [params.maxLevels.max1000, params.maxLevels.max2000, params.maxLevels.max4000, params.maxLevels.max8000, params.maxLevels.max1000,  params.maxLevels.max125, params.maxLevels.max250, params.maxLevels.max500];

          console.log(maxDb)

          if (hzList[i] === 1000) {
              db = await warmUp(hzList[i], db, calib, compensation, side, params, maxDb[i]);
              db = await mainTest(hzList[i], db, calib, compensation, side, params, params.rounds, i + j * 8, maxDb[i]);
              ctrArray[i + j * 8] = oneKHz_ctr;
              oneKHz_ctr += 1;
          } else {
              var definedElements = resultArray.filter(Boolean);
              var resultArray_count = definedElements.length;

              //If a threshold in 1kHz is more than 50dB, +10dB in the next frequencies.
              console.log("examination: resultArray: "+resultArray);
              console.log("examination: resultArray_count: "+resultArray_count);
              console.log("examination: dB value "+db);

              if (resultArray_count < 5) {
                  if (resultArray[0] > 50) {
                      db = resultArray[0] + 10;
                      if (db >= 100) { //If db reaches 100dB and no response, the db will start from 50dB in the next freqency.
                          db = 50;
                      }
                      db = Math.min(Math.max(db, 50), 100);
                  }
              } else if (resultArray_count >= 5 && resultArray_count < 9) {
                  if (resultArray[4] > 50) {
                      db = resultArray[4] + 10;
                      if (db >= 100) { //If db reaches 100dB and no response, the db will start from 50dB in the next freqency.
                          db = 50;
                      }
                      db = Math.min(Math.max(db, 50), 100);
                  }
              } else if (resultArray_count >= 9 && resultArray_count < 13) {
                  if (resultArray[8] > 50) {
                      db = resultArray[8] + 10;
                      if (db >= 100) { //If db reaches 100dB and no response, the db will start from 50dB in the next freqency.
                          db = 50;
                      }
                      db = Math.min(Math.max(db, 50), 100);
                  }
              } else if (resultArray_count >= 13 && resultArray_count < 17) {
                  if (resultArray[12] > 50) {
                      db = resultArray[12] + 10;
                      if (db >= 100) { //If db reaches 100dB and no response, the db will start from 50dB in the next freqency.
                          db = 50;
                      }
                      db = Math.min(Math.max(db, 50), 100);
                  }
              }

              db = await warmUp(hzList[i], db, calib, compensation, side, params, maxDb[i]);
              db = await mainTest(hzList[i], db, calib, compensation, side, params, params.rounds, i + j * 8, maxDb[i]);
          }

          resultArray[i + j * 8] = db;
          setCurrentIntensity(db);
          db = currentIntensity;

          if (hzList[i] !== 1000) {
              ctrArray[i + j * 8] = retest_ctr;
          }

          // Added
          console.log("Tulokset: " + hzList[i] + " Hz " + db + "db");
          isResumingRef.current = false; // Reset resume flag after resuming

           //If second test of 1000 hz differs over 5db from first tests starts from beginning. Here are 5 patterns.
            // In clinical setting 1000Hz is tested twice
          if (i === 4 && params.restart === 1 && !restarted) {
              if (!(resultArray[i + j * 8] < resultArray[j * 8] + 10 && resultArray[i + j * 8] > resultArray[j * 8] - 10) && retest_ctr < 3) {
                  if (retest_ctr === 1) {
                      oneKTh1 = resultArray[j * 8];
                      oneKTh2 = resultArray[i + j * 8];
                      i = 0;
                      resultArray[j * 8] = resultArray[i + j * 8];
                      ctrArray[j * 8] = ctrArray[i + j * 8];
                      retest_ctr += 1;
                  } else if (retest_ctr === 2) {
                      oneKTh3 = resultArray[i + j * 8];
                      if ((Math.abs(oneKTh2 - oneKTh3) < 10) || (Math.abs(oneKTh1 - oneKTh3)) < 10) {
                          oneKTh = oneKTh3;
                          if (side === 1) {
                              test_accR = 0;
                          } else {
                              test_accL = 0;
                          }
                          restarted = true;
                          retest_ctr += 1;
                      } else {
                          oneKTh = median(oneKTh1, oneKTh2, oneKTh3);
                          resultArray[j * 8] = oneKTh;
                          if (side === 1) {
                              test_accR = 1;
                          } else {
                              test_accL = 1;
                          }
                          restarted = true;
                          retest_ctr += 1;
                      }
                  }
              } else { //A gap between Th1 and Th2 is less than 10dB in 1K. The loop ends. 
                  oneKTh1 = resultArray[j * 8];
                  oneKTh2 = resultArray[i + j * 8];
                  console.log("5. The diffrence is less than 10dB: TH1 in 1K Hz: "+oneKTh1+", TH2 in 1K Hz: "+oneKTh2);
                  restarted = true;
                  resultArray[j * 8] = resultArray[i + j * 8];
                  ctrArray[j * 8] = ctrArray[i + j * 8];
                  if (side === 1) {
                      test_accR = 0;
                  } else {
                      test_accL = 0;
                  }
                  retest_ctr += 1;
              }
          }
      }

      oneKHz_ctr = 1;
      total_step_ctrR += total_warmUp_ctrR + total_main_ctrR;
      total_step_ctrL += total_warmUp_ctrL + total_main_ctrL;
      console.log("Examination: 1. side check: "+side);

      currentEarIndexRef.current++;

      //setCurrentEarIndex(prevEarIndex => prevEarIndex + 1);
      //console.log("Ear index in line 642", currentEarIndex)
      console.log("current ear index:", currentEarIndexRef.current)
      console.log("Examination: 1. side check: " + side);

     
      //currentEarIndex++;
      setCurrentFrequencyIndex(0);
      console.log("line 647 current frequency index at the end of test", currentFrequencyIndex)
  }

  if (!interruptRef.current) {
    endTime = new Date();
    console.log("WHEN STOPPING AM I CALLED line 647")
    console.log("WHEN STOPPING AM I CALLED, the hzzzzz in line 650")
    sessionStorage.setItem('resultArray', resultArray);
    sessionStorage.setItem('mode', params.mode);
    results(resultArray, params.mode, hzList);
  }

  //isExaminationRunningRef.current = false; // Ensure the test is stopped

  if (currentEarIndexRef.current  >= earList.length) {
      console.log("Test completed for both ears.");
      endTime = new Date();
      sessionStorage.setItem('resultArray', resultArray);
      sessionStorage.setItem('mode', params.mode);
      results(resultArray, params.mode, hzList);
  }
}


// Testing consists of 2 parts, the warmUp and the mainTest. Warmup is used to quickly find the frequency the patient can hear. 
// 15 dB up or down. 
async function warmUp(hz, db, calib, compensation,side, param, maxDb) {
  let warmUp_ctr = 0; // Added 
  heardRef.current = false;
  HeardOnceRef.current = (false);
  console.log("warmUpTest dB value: "+db);
  while (db > 0) {
      if (interruptRef.current) {
          console.log("warmUp: Test paused, exiting warmUp");
          break; // Exit loop if test is paused
      }
      //document.getElementById('demo').innerHTML = db
      
      heardRef.current = await soundTest(hz, db, calib, compensation, side, param, 0, 1);

      // If patinet presses the button i heard a sound.
      if (heardRef.current) {
        HeardOnceRef.current = true;
          db = Math.max(-10, db - 15);
          warmUp_ctr +=1;
      }
      else {
          if (HeardOnceRef.current) {
              db = Math.max(db, -10);
              warmUp_ctr +=1;
              break;
          }
          db += 15;
          if (db > maxDb) {
              db = db - 15;
              warmUp_ctr +=1;
              break;
          }
      }
  }
  // Added
  if (side===1){
      total_warmUp_ctrR +=warmUp_ctr;
  } else{
      total_warmUp_ctrL +=warmUp_ctr; 
  }
  // Added
  return new Promise(resolve => { resolve(db) });
}

// Main test for testing intensity level on a given frequency.
async function mainTest(hz, db, calib, compensation, side, param, rounds, index, maxDb) {
  let TH = [9999, 9998, 9997, 9996, 9995]; // Added 9996 and 9995
  let iThr = 0;
  let roundCounter = 0;
  let startdb = db;
  let heardArray = [];

  heardRef.current = false;
  let mainTest_counter = 0; // How many steps are taken
  let satadB_counter = 0; // Check a test in 100dB 
  console.log("mainTest dB value: "+db);
  // Loop the testing procedure until same level is heard three times in the clinical setting. When a sound is heard, drop 10 dB and if not heard rise the dB level by 5dB
  while (true) {
      if (interruptRef.current) {
          console.log("mainTest: Test paused, exiting mainTest");
          break; // Exit loop if test is paused
      }

      while (heardRef.current && db > -10) {
          db = Math.max(db - 10, -10);
          // Dispaly the result in the screen
          //document.getElementById('demo').innerHTML = db;
          if (db > -5) { //Does not play -10db, test continues with -5db from row 95->
            heardRef.current = await soundTest(hz, db, calib, compensation, side, param, 2, roundCounter + 1);
              mainTest_counter += 1; //A counter is added
          }
          else {
              db = -10;
          }
      }
      db = Math.min(maxDb-maxDb%5, db + 5);

      heardRef.current = await soundTest(hz, db, calib, compensation, side, param, 1, roundCounter + 1);

      if (interruptRef.current) {
          console.log("Test paused, exiting mainTest after soundTest");
          break; // Check again after async call
      }
            
      console.log("MAINTEST", heardRef.current)
      if (heardRef.current) { //Sound is heard
          heardArray.push(db);
          console.log("heardArray:"+heardArray);
          TH[iThr] = db;
          console.log("mainTest thresholds:"+TH);
          console.log("mainTest TH[4]: "+TH[4]);
          //if (TH[0] === TH[1] || TH[1] === TH[2] || TH[0] === TH[2]) {
          if ((TH[0] === TH[1] && TH[1] === TH[2]) ||
              (TH[0] === TH[1] && TH[1] === TH[3]) ||
              (TH[0] === TH[1] && TH[1] === TH[4]) ||
              (TH[0] === TH[2] && TH[2] === TH[3]) ||
              (TH[0] === TH[2] && TH[2] === TH[4]) ||  
              (TH[0] === TH[3] && TH[3] === TH[4]) ||
              (TH[1] === TH[2] && TH[2] === TH[3]) ||
              (TH[1] === TH[2] && TH[2] === TH[4]) ||
              (TH[1] === TH[3] && TH[3] === TH[4]) ||
              (TH[2] === TH[3] && TH[3] === TH[4])) {    
              console.log("Three numbers, which a patient responsed are the same out of five");
              break;
          //} else if (TH[2] != 9997) {
          } else if (TH[4] !== 9995) {    
              console.log("Game over!");
              roundCounter++;
              if (roundCounter >= rounds) {  
                  console.log("Ei saatu kunnon lopputulosta laitetaan korkein kuultu\n" + heardArray) // "We did onot get reliable measurement of the hearing result"
                  db = Math.max(...heardArray);
                  uncertain[index] = 1;
                  valid_data = 1; //If a patient failed to responde 3 same thresholds, the data becomes unreliable.
                  console.log("mainTest: unreliable data in 100dB "+uncertain);
                  break;
              }
              TH = [9999, 9998, 9997, 9996, 9995]; //Added 9996 && 9995
              db = startdb + 20; //Test starts from +10db next round
              iThr = 0;
              mainTest_counter += 1; //A counter is added
              console.log("mainTest rouncCounter: "+roundCounter);
              continue;
          }
          console.log("mainTest responses1: "+TH);
          iThr++;
      }
      else if (db >= maxDb-maxDb%5) { //Sound is not heard or no answer. Max level was reached and moving to next frwqucney
          console.log("mainTest not heard in 100dB: "+db);
          // This is added if clause, if some threshold values contained in TH, a test in 100 dB can be no response.  
          if ((TH[0] === 9999) && (TH[1] === 9998) && (TH[2] === 9997) && (TH[3] === 9996) && (TH[4] === 9995)){
              console.log("A test goes to the next frequency");
              // if no response in 100dB, it is unreliable. 
              uncertain[index] = 1;
              valid_data = 1; 
              break; // if no previous response in TH, a test goes to the next frequency.
          } else { // if there are responses in TH, a test continues in the same frequency.
              if (satadB_counter < 2) { //Allowing 2-time ignorance in 100dB
                  console.log("A test continues in the same frequency for only two times.");
                  satadB_counter ++;
                  continue;
              } else{
                  break;
              }
          }
      }
      //mainTest_counter += 1; //A counter is added
      console.log("mainTest round: "+rounds);
      //console.log("mainTest mainTest_counter: "+mainTest_counter);
  }
  // Added
  if (side===1){
      total_main_ctrR+=mainTest_counter;
  } else {
      total_main_ctrL+=mainTest_counter;
  }
  // Added
  return new Promise(resolve => {
      resolve(db);
  });
}



// Added a function for creating a temporary Json file AAA
function interruptFlag() {
  console.log("Interruptflag: invalid data");

  // Ensure startTime is defined before using it
  if (!startTime) {
    console.error("startTime is not defined.");
    return;
  }
  
  var now = new Date();
  let testTime = parseInt((new Date().getTime() - startTime.getTime()) / 1000);
  let testSec = testTime % 60;
  let testMin = (testTime - testSec) / 60;

  interruptRef.current =  true; // Pause the testsetInterrupt(true);  // A test is interrupted. 

  if (currentEarIndex === 0) { // Result is inaccurate.
      test_accR = 1; // right ear
      test_accL = 1;
  } else {
      test_accL = 1; // left ear
  }
  //test_acc = 1; // Inaccurate data

  valid_data = 1; // the data is interrupted and terminated by a patient. Meaning invalid.
  testInfoData = {
          "Patient_ID": patient_ID, //For initial patient test
          //"Gender":sessionStorage.getItem('gender'), //Adding patient's gender information
          "Testiaika": (testMin + ' min, ' + testSec + ' s'),
          "Keskeytetty": interrupt,
          "AccuracyR": test_accR, //Added: 0: accurate, 1: inaccurate in Right
          "AccuracyL": test_accL, //Added: 0: accurate, 1: inaccurate in Left
          "Validity": valid_data, //0: completed dataset, 1: incompleted dataset (Probably patient cancelled a test)
          "totalWarmUpStepsR": total_warmUp_ctrR, //Added: the number of warm-up steps on right to find thresholds
          "totalWarmUpStepsL": total_warmUp_ctrL, //Added: the number of warm_up steps to on left find thresholds
          "totalMainStepsR": total_main_ctrR, //Added: the number of main test steps on right to find thresholds
          "totalMainStepsL": total_main_ctrL //Added: the number of main test steps on left to find thresholds
      }
  json.testInfo.push(testInfoData);
  // Generate filename based on the current date and time
  var filename = "Invalid_" + patient_ID + "_" + now.getHours() + "." + now.getMinutes() + "_" + now.getDate() + "." + (now.getMonth() + 1) + "." + now.getFullYear() + ".json";

  // Convert JSON object to a string
  //var jsonString = JSON.stringify(json);

  // AJAX request to Flask server
  fetch('/save_audiometry_results', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          filename: filename,
          data: json
      })
  })
  interruptRef.current =  false; // Pause the testsetInterrupt(false);
}
// Added a function for creating a temporary Json file

async function soundTest(hz, db, calib, compensation, side, param, testPhase, roundCounter) {

  console.log("soundTest interrupt", {int: interruptRef.current})

  if (interruptRef.current) {
    console.log("soundTest interrupt inside if interrupt", { interrupt: interruptRef.current });
    pauseTest(db);
    endTime = new Date();
    results("Results array", resultArray, param.mode, [1000, 2000, 4000, 8000, 1000, 500, 250, 125]);
    return;
}

  console.log("soundTest side is: ", side)
  calib = getCalibrationFactor(side, hz, db);
  const headphoneModel = param.headphoneModel || 'defaultModel';
  console.log("HeadphoneModel chosen", headphoneModel)
  compensation = await getCompensationFactor(headphoneModel, hz);
  
  console.log("Calib inside the soundTest function is:", calib)
  console.log("Headphone compensation in soundTest:", compensation)

  //let side = earList[currentEarIndex];
  soundStartTime = Date.now();
  playWave(hz, db, calib, compensation, side);
  pressedRef.current = false;
  let occurrence = (hz === 1000) ? oneKHz_ctr : retest_ctr; //Added a counter for test occurence
  let num = parseInt(Math.random() * 2000)
  if (!pressStartTime) {
      responseDuration = "NA";
  } else if ((pressStartTime) && (buttonPressCount >= maxBtnPress)) {
      responseDuration = "NA";
  } else {
      responseDuration = Math.abs(pressStartTime - soundStartTime);
  }   
  console.log("soundTest: pressStartTime: " + pressStartTime);
  console.log("soundTest: soundStartTime2: " + soundStartTime);
  console.log("soundTest: Response duration: " + responseDuration);
  console.log("Print out params in SoundTest Function", param)
  console.log('soundTest: params.ResponseTime', param.ResponseTime)
  TotalTime = param.ResponseTime + num;
  
  console.log('soundTest: Total Time ', TotalTime)

  return new Promise(resolve => {
      setTimeout(() => {
          var soundData 
          soundData = {  //using global
              "Hz": hz,
              "dB taso": db,
              "Kuultu": pressed,
              "Korva": side,
              "Vaihe": testPhase,
              "Kierros": roundCounter,
              "Occurrence": occurrence, //Added test occurrence
              "response" : responseDuration
          }

          console.log("line 931", { pressed: pressedRef.current }); // Use pressedRef here
          if (!pressedRef.current) {
              // Mark the canvas with a gray "X" at the current position
              //drawResponseMark(hz, db, false); // drawResponseMark draws a trinagle in the screen, when a sounf is not heard. False indicates not heard
              }
          json.sound.push(soundData);
          
          resolve(pressedRef.current);
      }, (TotalTime)); //Total Time given for response 5s + random 0-3 s
  })
}

const NormalFrequencyArray = [1000, 2000, 4000, 8000, 1000, 500, 250, 125]
//const FullFrequencyArray = [1000, 2000, 3000, 4000, 6000, 8000, 1000, 750, 500, 250, 125]

function generateOrderArray(normalArray, testFrequencies) {
  if (!Array.isArray(testFrequencies)) {
    console.error("testFrequencies is not an array:", testFrequencies);
    return [];  // Return an empty array to avoid further errors
  }

  let orderArray = [];
  for (let freq of testFrequencies) {
      // Find the first occurrence of the frequency in the normal array
      let index = normalArray.indexOf(freq);
      if (index !== -1) {
          // Add the index to the order array
          orderArray.push(index);
      }
  }
  return orderArray;
}


var dbLimitData

// Send an Ajax request to flask server with the JSON data.
function results(resultArray, mode, hzList, param, hz) {
  var hzList = [1000, 2000, 4000, 8000, 1000, 500, 250, 125];
  console.log('Hz List in results read:', hzList);
  console.log({interrupt: interruptRef.current})

  if (!interruptRef.current) { // If test was not interrupted, get the results data and send the full resuts data throug Flask and show the results in the results page
      interruptRef.current =  false; // Pause the testsetInterrupt(false);
      let testTime = parseInt((endTime.getTime() - startTime.getTime()) / 1000);
      let testSec = testTime % 60;
      let testMin = (testTime - testSec) / 60;

      testInfoData = { 
          //"Etunimi": sessionStorage.getItem('firstName'),
          //"Sukunimi": sessionStorage.getItem('lastName'),
          //"Henkilötunnus": sessionStorage.getItem('patientId'),
          "Patient_ID": patient_ID, //For initial patient test
          //"Gender":sessionStorage.getItem('gender'), //Adding patient's gender information
          "Testiaika": (testMin + ' min, ' + testSec + ' s'),
          "Keskeytetty": interrupt,
          "AccuracyR": test_accR, //Added: 0: accurate, 1: inaccurate in Right
          "AccuracyL": test_accL, //Added: 0: accurate, 1: inaccurate in Left
          "Validity": valid_data, //Added: a patient completes a test
          "totalWarmUpStepsR": total_warmUp_ctrR, //Added: the number of warm-up steps on right to find thresholds
          "totalWarmUpStepsL": total_warmUp_ctrL, //Added: the number of warm_up steps to on left find thresholds
          "totalMainStepsR": total_main_ctrR, //Added: the number of main test steps on right to find thresholds
          "totalMainStepsL": total_main_ctrL //Added: the number of main test steps on left to find thresholds
      }
      json.testInfo.push(testInfoData);
      // Print out limits. CUrrently hardcoded as 100 dB. 
      //let dbLimitData = {
      //dbLimitData = {
      //    '125': param.max125,
      //    '250':param.max250,
      //    '500':param.max500,
      //    '1000':param.max1000,
      //    '2000':param.max2000,
      //    '4000':param.max4000,
      //    '8000':param.max8000,
     // }
      json.dbLimits = dbLimitData;

      // if mode == 0, both ears are tested. First right, then left.
      //
      if (mode === 0) {
          // Generate orderArray based on hzList
          console.log("RESULTS SECTION mode 0",NormalFrequencyArray, hzList )
          let orderArray = generateOrderArray(NormalFrequencyArray, hzList);
          let doubleOrderArray = [];

          for (let index of orderArray) {
              doubleOrderArray.push(index); // Right ear index
              doubleOrderArray.push(index + 8); // Corresponding left ear index
          }
          orderArray = doubleOrderArray;
          
          console.log("orderArray: ", orderArray)
          //orderArray = [7, 6, 5, 4, 1, 2, 3, 15, 14, 13, 12, 8, 9, 10];
          for (let i = 0; i < orderArray.length; i++) {
              let orderIndex = orderArray[i];
              let hzIndex = orderIndex % NormalFrequencyArray.length; // Adjust based on how your arrays are structured
              let frequency = NormalFrequencyArray[hzIndex]; // Use the mapped index to access the correct frequency
              let korva = orderIndex < NormalFrequencyArray.length ? 1 : -1; // Example logic for determining 'Korva'
              console.log('Current Hz index:', orderArray[i]);
              let resultData = {
                  "Hz": frequency,
                  "dB taso": resultArray[orderIndex],
                  "Korva": korva,
                  "Occurrence": ctrArray[orderArray[i]], // Added the test round number
                  "Epavarma": uncertain[orderIndex]
              }
              console.log('resultData:', resultData);
              json.results.push(resultData);
          }
          console.log("results: Epavarma "+uncertain);
      }
      // Only one ear tested
      else {
          // Generate orderArray based on hzList
          console.log("RESULTS SECTION mode 1 or 2",NormalFrequencyArray, hzList )
          let orderArray = generateOrderArray(NormalFrequencyArray, hzList);
          console.log("orderArray: ", orderArray)
          for (let i = 0; i < orderArray.length; i++) {
              let orderIndex = orderArray[i];
              let hzIndex = orderIndex % NormalFrequencyArray.length; // Adjust based on how your arrays are structured
              let frequency = NormalFrequencyArray[hzIndex]; // Use the mapped index to access the correct frequency
              let korva = orderIndex < NormalFrequencyArray.length ? 1 : -1; // Example logic for determining 'Korva'
              console.log('Current Hz index:', orderArray[i]);
              let resultData = {
                  "Hz": frequency,
                  "dB taso": resultArray[orderIndex],
                  "Korva": korva,
                  "Occurrence": ctrArray[orderArray[i]], // Added the test round number
                  "Epavarma": uncertain[orderIndex]
              }
              console.log('resultData:', resultData);
              json.results.push(resultData);
          }
      }
      // Create name string for file, reuse endTime for getting total test time
      let filename = `${patient_ID}_${endTime.getDate().toString().padStart(2, '0')}.${(endTime.getMonth() + 1).toString().padStart(2, '0')}.${endTime.getFullYear()}_${endTime.getHours().toString().padStart(2, '0')}.${endTime.getMinutes().toString().padStart(2, '0')}.json`;

      console.log("Tiedostonimi: " + filename);

      // Only proceed if the test is not interrupted. This is for sending the JSON to Flask backend    
      if (!interruptRef.current) {
              // AJAX request to Flask server
              fetch('/save_audiometry_results', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  filename: filename,
                  data: json
              })
          
          })
          .then(response => {
              console.log(response);
          if (!response.ok) {
              throw new Error('Network response was not ok: ' + response.statusText);
          }
          return response.json();
              })
          .then(data => {
              if (data.success) {
                  // Redirect to the results page
                  window.location.href = 'audiometry_results3';
              } else {
                  // Handle error. logging only for now
                  console.error('Error saving results');
              }
          })
          .catch(error => {
              console.error('Error:', error);
          });
      }
  } else {
      // Test was interrupted, do not process results.
      // What to do next? Redirect somewhere
      console.log("Test was interrupted, results not processed.");
  }    

  }

  



// dEFINE THE PROPERTIES OF THE SOUND BEING PLAYED
function playWave(hz, db, calib, compensation, side, duration= 1.5) {
    if (audioContext.state === 'suspended') {
        audioContext.resume();
    }

    // Stop any currently playing sound
    if (currentSrc) {
        currentSrc.stop();
        currentSrc.disconnect();
        currentSrc = null; // Reset to ensure the sound stops
    }

    let oscillator = audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(hz, audioContext.currentTime);

    let gainNode = audioContext.createGain();
    let dbMultiplier = (10 ** ((db + calib + compensation - 80) / 20));

    if (!isFinite(dbMultiplier)) {
        console.error(`Invalid gain value computed: ${dbMultiplier}`);
        dbMultiplier = 0; // Fallback to 0
    }

    console.log("dB value",db); // SHow the dB in the browser console
    sum = db+calib+compensation
    console.log("dB value korjattu",sum); // SHow the dB in the browser console

    gainNode.gain.value = dbMultiplier;
    console.log("gainNode value", gainNode.gain.value );

    //Measure time taken to do the test
    let currentTime = new Date(); //Tämä on ajan testausta varten
    let elapsedTime = currentTime - startTime;
    // Convert milliseconds into minutes, seconds, and milliseconds
    let seconds = Math.floor(elapsedTime / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    console.log(hz + "hz " + db + "db " +  dbMultiplier + " volume " +  minutes + "min " + seconds + "s " );
    setCurrentIntensity(db); // ... current intensity

    // Play the side either from the right or left side using the panner
    let panner = audioContext.createStereoPanner();
    panner.pan.setValueAtTime(side, audioContext.currentTime);

    oscillator.connect(gainNode);
    gainNode.connect(panner);
    panner.connect(audioContext.destination);

    oscillator.start();
    oscillator.stop(audioContext.currentTime + duration);
    currentSrc = oscillator; // Save the source to stop it later
}





  
  return (
    <div className='h-screen bg-gray-300 overflow-y-auto flex justify-center items-center'>
      <div className="inline-flex flex-col items-center gap-8 absolute top-[364px] left-[560px]">
        <div className="flex flex-col w-[800px] items-start gap-6 relative flex-[0_0_auto]">
          <div className="flex flex-col items-center justify-center relative self-stretch w-full flex-[0_0_auto]">
            <div className="relative self-stretch mt-[-1.00px] font-caption-large text-digikuulo-colors-gray-700 text-center">
              KUULOKYNNYSTESTI
            </div>
            <p className="relative self-stretch font-display-lg-bold text-digikuulo-colors-gray-700 text-center">
              Paina nappia, kun kuulet äänen
            </p>
          </div>
        </div>
        <div className="inline-flex items-center relative flex-[0_0_auto]">
          <div
            className="rounded-[130px] border-[20px] border-solid border-digikuulo-colors-gray-300 relative w-[260px] h-[260px]"
            style={{ backgroundColor: lightColor }}
          />
        </div>
        <div className="inline-flex flex-col items-center gap-2 p-1 relative flex-[0_0_auto] rounded-[15px] border-2 border-solid border-digikuulo-colors-indigo-800">
          <button
            onClick={handleClick}
            className="inline-flex items-center justify-center gap-4 px-6 py-4 bg-digikuulo-colors-brand-500 rounded shadow-shadow-xs-focused-4px-primary-100"
            disabled={buttonDisabled}
          >
            {isExaminationRunning ? "Kuulin äänen" : "Aloita testi"}
          </button>
        </div>
      </div>
      <div className='flex flex-col justify-center items-center mt-14 flex-grow relative w-full'>
        <div className='text-sm gap-x-2 flex py-2 justify-evenly px-4 bg-white text-black rounded-md'>
          <div className='flex gap-x-1'  onClick={() => showModal("Do you want to continue or go back?")}>
              {/* Custom Modal */}
              <CustomModal
                message="Do you want to continue or go back?"
                isOpen={isModalOpen}
                onClose={navigateToPreviousPage} // Navigate back
                onContinue={resumeTest} // Continue the test
              />
            <div className="flex flex-wrap justify-between items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-lg">

            {/* Keskeytä Button */}
            <button className="bg-white text-gray-800 border border-gray-300 py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-400 text-lg md:text-xl max-sm:text-sm">
                Keskeytä
            </button>

            {/* Apua Button with Info Icon */}
            <div className="flex items-center gap-2 bg-white text-gray-800 border border-gray-300 py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-400">
                <img src={InfoIcon} alt="ℹ️" className="w-6 h-6 md:w-5 md:h-5 max-sm:w-4 max-sm:h-4" />
                <button onClick={pauseTest} className="text-lg md:text-xl max-sm:text-sm">Apua</button>
            </div>

            {/* Pysäytä Button with Pause Icon */}
            <div className="flex items-center gap-2 bg-white text-gray-800 border border-gray-300 py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-400">
                <img src={PauseIcon} alt="Pause" className="w-6 h-6 md:w-5 md:h-5 max-sm:w-4 max-sm:h-4" />
                <button onClick={pauseTest} className="text-lg md:text-xl max-sm:text-sm">Pysäytä</button>
            </div>

            {/* Jatka Button */}
            <div className="flex items-center gap-2 bg-white text-gray-800 border border-gray-300 py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-400">
                <button onClick={resumeTest} className="text-lg md:text-xl max-sm:text-sm">Jatka</button>
            </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Audiogram;
