import React, { useCallback, useEffect, useRef, useState } from 'react';
import SettingsNavbar from './SettingsNavbar'; // Navbar component for settings page
import ButtonUtil from '../../Global/ButtonUtil'; // Reusable button utility component
import { HeadPhoneIconv1, HomeIcon, SpeakersIcon, TestIconv1 } from '../../../assets/Icons'; // Icons for buttons
import { useNavigate } from 'react-router-dom'; // For programmatic navigation
import { useDispatch, useSelector } from 'react-redux'; // Redux hooks for state management
import {
    setAdaptiveSetting, setEarsSetting, setHeadPhoneLeftEarSetting, setHeadPhoneRightEarSetting,
    setLeftEarCalibrationSetting, setMuutumatonSetting, setNumberSeriesSetting, setRightEarCalibrationSetting,
    setSoundSourceSetting, setSpeakerLeftEarSetting, setSpeakerRightEarSetting, setSpeechVolumeSetting,
    setStereoSetting
} from '../../../Slices/dinSettingSlice'; // Actions for Redux state
import { toast, ToastContainer } from 'react-toastify'; // Toast notifications
import ModalComponent from './ModalComponent'; // Modal component for booth selection
import isElectron from '../../../Functions/isElectron'; // Function to check if the app is running in Electron

const SettingsPage = () => {
    const navigate = useNavigate(); // Hook to navigate between routes
    const dispatch = useDispatch(); // Hook to dispatch Redux actions

    // Modal state management
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBooth, setSelectedBooth] = useState(null); // To track the selected booth from the modal

    // Handlers to open and close the modal
    const handleOpenModal = useCallback(() => setIsModalOpen(true), []);
    const handleCloseModal = useCallback(() => setIsModalOpen(false), []);

    // Handler to save booth selection from modal
    const handleSaveModal = useCallback((booth) => {
        setSelectedBooth(booth);
        toast.success(`Booth "${booth.name}" selected`);
        setIsModalOpen(false);
    }, []);

    // Buttons for calibration options
    const buttonsUtil = [
        { text: 'Kuulokkeet', link: '/HeadPhoneCalibrationPage', icon: HeadPhoneIconv1 },
        { text: 'Kaiuttimet', link: '/SpeakerCalibrationPage', icon: SpeakersIcon }
    ];

    // Research protocol and settings state management
    const [adaptive, setAdaptive] = useState(2);
    const [muutumaton, setMuutumaton] = useState(1);
    const [ears, setEars] = useState('right');
    const [firstEar, setFirstEar] = useState('right');
    const [soundSource, setSoundSource] = useState('headPhone');
    const [numberSeries, setNumberSeries] = useState('list1');
    const [speechVolume, setSpeechVolume] = useState(65);

    // Fetching calibration settings from Redux state
    const headPhoneLeftEarSetting = useSelector((state) => state.dinState.headPhoneLeftEarSetting);
    const headPhoneRightEarSetting = useSelector((state) => state.dinState.headPhoneRightEarSetting);
    const speakerLeftEarSetting = useSelector((state) => state.dinState.speakerLeftEarSetting);
    const speakerRightEarSetting = useSelector((state) => state.dinState.speakerRightEarSetting);

    // Fetching stereo setting
    const stereoSetting = useSelector((state) => state.dinState.stereoSetting);
    const [stereo, setStereo] = useState(false);


    // To hold default settings from file
    const [defaultSettings, setDefaultSettings] = useState(null);
    const defaultSettingsRef = useRef(null); // Reference to persist default settings
    // Default settings object for fallback
    const defaultSettingsFallback = {
        adaptive: 2,
        muutumaton: 1,
        ears: ['right'],
        speechVolume: 65,
        numberSeries: 'list1',
        leftEarCalibration: 0,
        rightEarCalibration: 0,
        firstEar: 'right',
        soundSource: 'headPhone',
        speakerLeftEarSetting: 0,
        speakerRightEarSetting: 0,
        headPhoneLeftEarSetting: 0,
        headPhoneRightEarSetting: 0,
        stereoSetting: false
    };
    // Function to load the settings
    const loadSettings = async () => {
        if (isElectron() && window.electron.isElectron && window.electron.loaddinSettings) {
            try {
                const response = await window.electron.loaddinSettings();
                if (response.success) {
                    let loadedSettings = response.data || {}; // Default to empty object if no data

                    // Merge loaded settings with fallback default settings to ensure all fields are present
                    const mergedSettings = {
                        ...defaultSettingsFallback, // Start with default settings
                        ...loadedSettings, // Override with any loaded settings
                        ears: loadedSettings.ears && loadedSettings.ears.length > 0 ? loadedSettings.ears : defaultSettingsFallback.ears // Handle ears array explicitly
                    };

                    // Update state and reference with merged settings
                    defaultSettingsRef.current = mergedSettings;
                    setDefaultSettings(mergedSettings);

                    // Set various settings from loaded data
                    setAdaptive(mergedSettings.adaptive);
                    setMuutumaton(mergedSettings.muutumaton);
                    setEars(mergedSettings.ears.includes('both') ? 'both' : mergedSettings.ears[0]);
                    setFirstEar(mergedSettings.firstEar);
                    setNumberSeries(mergedSettings.numberSeries);
                    setSpeechVolume(mergedSettings.speechVolume);

                    // Dispatch loaded settings to Redux
                    dispatch(setAdaptiveSetting(mergedSettings.adaptive));
                    dispatch(setMuutumatonSetting(mergedSettings.muutumaton));
                    dispatch(setEarsSetting(mergedSettings.ears));
                    dispatch(setSpeechVolumeSetting(mergedSettings.speechVolume));
                    dispatch(setNumberSeriesSetting(mergedSettings.numberSeries));
                    dispatch(setLeftEarCalibrationSetting(mergedSettings.leftEarCalibration));
                    dispatch(setRightEarCalibrationSetting(mergedSettings.rightEarCalibration));
                    dispatch(setHeadPhoneLeftEarSetting(mergedSettings.headPhoneLeftEarSetting));
                    dispatch(setHeadPhoneRightEarSetting(mergedSettings.headPhoneRightEarSetting));
                    dispatch(setSpeakerLeftEarSetting(mergedSettings.speakerLeftEarSetting));
                    dispatch(setSpeakerRightEarSetting(mergedSettings.speakerRightEarSetting));

                    toast.success('Settings loaded successfully!');
                } else {
                    // Load default settings if no previous settings found
                    defaultSettingsRef.current = defaultSettingsFallback;
                    setDefaultSettings(defaultSettingsFallback);
                    toast.info('No previous settings found. Loaded default settings.');
                }
            } catch (error) {
                console.error(error.message);
                toast.error('Error loading settings from file.');
            }
        } else {
            toast.info('[+] Skipping Load Settings as we are in browser environment');
        }
    };


    // Function to confirm and save all selected settings
    const confirmSelection = async (e) => {
        try {
            e.preventDefault();
            // Construct settings object from current state
            const settings = {
                adaptive,
                muutumaton,
                ears: stereo ? ["both"] : (
                    ears === 'both'
                        ? [firstEar === 'left' ? 'left' : 'right', firstEar === 'left' ? 'right' : 'left']
                        : [ears]
                ),
                speechVolume: parseFloat(speechVolume),
                numberSeries,
                leftEarCalibration: soundSource === 'headPhone' ? headPhoneLeftEarSetting : speakerLeftEarSetting,
                rightEarCalibration: soundSource === 'headPhone' ? headPhoneRightEarSetting : speakerRightEarSetting,
                firstEar,
                soundSource,
                speakerLeftEarSetting,
                speakerRightEarSetting,
                headPhoneLeftEarSetting,
                headPhoneRightEarSetting,
                stereoSetting: stereo
            };

            // Dispatch settings to Redux
            dispatch(setAdaptiveSetting(adaptive));
            dispatch(setMuutumatonSetting(muutumaton));
            dispatch(setEarsSetting(settings.ears));
            dispatch(setSpeechVolumeSetting(parseFloat(speechVolume)));
            dispatch(setNumberSeriesSetting(numberSeries));
            dispatch(setSoundSourceSetting(soundSource));
            dispatch(setLeftEarCalibrationSetting(settings.leftEarCalibration));
            dispatch(setRightEarCalibrationSetting(settings.rightEarCalibration));
            dispatch(setHeadPhoneLeftEarSetting(settings.headPhoneLeftEarSetting));
            dispatch(setHeadPhoneRightEarSetting(settings.headPhoneRightEarSetting));
            dispatch(setSpeakerLeftEarSetting(settings.speakerLeftEarSetting));
            dispatch(setSpeakerRightEarSetting(settings.speakerRightEarSetting));
            dispatch(setStereoSetting(settings.stereoSetting));

            toast.success('Settings Updated');
            console.log('Settings Protocol:', settings);

            // Save settings to file if running in Electron
            if (isElectron() && window.electron.isElectron && window.electron.savedinSettings) {
                try {
                    const response = await window.electron.savedinSettings(settings);

                    if (response.success) {
                        toast.success('Settings saved to file!');
                        loadSettings();
                    } else {
                        toast.error(response.message);
                    }

                } catch (error) {
                    toast.error('Error saving settings to file.');
                }
            } else {
                toast.info('[+] Skipping saving as we are in browser environment');
            }
        } catch (error) {
            toast.error('Something went wrong while confirming selection.');
        }
    };


    // Load settings from a file if running in Electron
    useEffect(() => {

        loadSettings();

    }, [dispatch]);

    useEffect(() => {
        return () => {
            console.log('unmount');
        };
    }, []);


    useEffect(() => {
        // Add event listener to prevent form submission
        const handleFormSubmit = (e) => {
            e.preventDefault();
            console.log('Form submission was prevented.');
        };

        window.addEventListener('submit', handleFormSubmit);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('submit', handleFormSubmit);
        };
    }, []);

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col p-6">
            {/* Navbar for navigation */}
            <SettingsNavbar>
                <ButtonUtil icon={HomeIcon} handleClick={() => navigate('/LandingPage')} text='Home' />
                <ButtonUtil icon={TestIconv1} handleClick={() => navigate('/TestLandingPage')} text='Back to Test' />
                <ButtonUtil icon={TestIconv1} handleClick={() => navigate('/AudiogramCalibration')} text='Audiogrammin kalibrointi' />
            </SettingsNavbar>

            <div className="px-6 py-8">
                <div>
                    <p className="text-gray-800 text-4xl md:text-5xl lg:text-6xl font-bold">Settings</p>
                </div>

                {/* Calibration Section */}
                <div className="mt-8">
                    <p className="text-gray-700 text-2xl font-semibold mb-4">Adjust Calibrations</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {buttonsUtil.map((btn, index) => (
                            <div
                                key={index}
                                className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer flex flex-col items-center justify-center"
                                onClick={() => navigate(btn.link, { state: { soundSource, defaultSettings: defaultSettingsRef.current } })}
                            >
                                <ButtonUtil icon={btn.icon} text={btn.text} />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Research Protocol Section */}
                <div className="mt-8">
                    <p className="text-gray-700 text-2xl font-semibold mb-4">Research Protocol Selection</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {/* Adaptive Setting */}
                        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer">
                            <label className="text-gray-600 text-lg font-medium mb-2">Adaptive</label>
                            <select value={adaptive} onChange={(e) => setAdaptive(Number(e.target.value))} className="p-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-blue-200">
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                            </select>
                        </div>

                        {/* Muutumaton Setting */}
                        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer">
                            <label className="text-gray-600 text-lg font-medium mb-2">Muutumaton</label>
                            <select value={muutumaton} onChange={(e) => setMuutumaton(Number(e.target.value))} className="p-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-blue-200">
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                            </select>
                        </div>

                        {/* Ears Setting */}
                        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer">
                            <label className="text-gray-600 text-lg font-medium mb-2">Ears</label>
                            <select value={ears} onChange={(e) => setEars(e.target.value)} className="p-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-blue-200">
                                <option value="left">Left</option>
                                <option value="right">Right</option>
                                <option value="both">Both</option>
                            </select>
                        </div>


                        {/* First Ear Setting (visible if 'both' is selected) */}
                        {ears === 'both' && (
                            <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer">
                                <label className="text-gray-600 text-lg font-medium mb-2">Which Ear First</label>
                                <select value={firstEar} onChange={(e) => setFirstEar(e.target.value)} className="p-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-blue-200">
                                    <option value="left">Left</option>
                                    <option value="right">Right</option>
                                </select>
                            </div>
                        )}
                        {/* Stero */}
                        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer">
                            <label className="text-gray-600 text-lg font-medium mb-2">Stereo</label>
                            <select value={stereo} onChange={(e) => setStereo(e.target.value === "true")} className="p-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-blue-200">
                                <option value={false}>NO</option>
                                <option value={true}>YES</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* Other Settings */}
                <div className="mt-8">
                    <p className="text-gray-700 text-2xl font-semibold mb-4">Other Settings</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {/* Number Series Setting */}
                        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer">
                            <label className="text-gray-600 text-lg font-medium mb-2">Select Number Series:</label>
                            <select value={numberSeries} onChange={(e) => setNumberSeries(e.target.value)} className="p-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-blue-200">
                                <option value="list1">List 1</option>
                                <option value="list2">List 2</option>
                                <option value="list3">List 3</option>
                                <option value="list4">List 4</option>
                                <option value="list5">List 5</option>
                                <option value="list6">List 6</option>
                                <option value="list7">List 7</option>
                                <option value="list8">List 8</option>
                                <option value="list9">List 9</option>
                            </select>
                        </div>

                        {/* Speech Volume Setting */}
                        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer">
                            <label className="text-gray-600 text-lg font-medium mb-2">Adjust Speech Volume:</label>
                            <select value={speechVolume} onChange={(e) => setSpeechVolume(e.target.value)} className="p-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-blue-200">
                                <option value={65}>Vol 65 dB</option>
                                <option value={60}>Vol 60 dB</option>
                                <option value={57}>Vol 57 dB</option>
                                <option value={55}>Vol 55 dB</option>
                                <option value={52.5}>Vol 52.5 dB</option>
                                <option value={50}>Vol 50 dB</option>
                                <option value={47.5}>Vol 47.5 dB</option>
                                <option value={45}>Vol 45 dB</option>
                            </select>
                        </div>

                        {/* Booth Selection (opens modal) */}
                        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer" onClick={handleOpenModal}>
                            <p className="text-gray-600 text-lg font-medium mb-2">Select booth:</p>
                            <button className="text-[#344054] px-0 py-2 rounded-md transition-colors duration-300 ease-in-out hover:bg-gray-400 hover:text-gray-500 cursor-pointer">
                                Select the booth for this setting
                            </button>
                        </div>

                        {/* Sound Source Selection */}
                        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer">
                            <label className="text-gray-600 text-lg font-medium mb-2">SoundSource</label>
                            <select value={soundSource} onChange={(e) => setSoundSource(e.target.value)} className="p-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-blue-200">
                                <option value="headPhone">HeadPhone</option>
                                <option value="speaker">Speaker</option>
                            </select>
                        </div>
                    </div>

                    {/* Confirm Button */}
                    <div className="mt-8">
                        <button
                            type="button" // Ensures the button doesn't trigger form submission
                            onClick={confirmSelection}
                            className="text-sm rounded-md bg-white px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-gray-400 hover:text-gray-500 text-[#344054] cursor-pointer"
                        >
                            Save settings
                        </button>
                    </div>
                </div>

                {/* Modal for booth selection */}
                <ModalComponent
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveModal}
                />
            </div>

            {/* Toast container for notifications */}
            <ToastContainer />
        </div>
    );
};

export default SettingsPage;
