import React from 'react';

const UserDisplaySection = ({ user, bannerText = 'Tervetuloa kuulotestiin! Alta löydät sinulle määrätyt kuulotestit.' }) => {
    return (
        <div className='flex flex-col items-center justify-center '>
            <div>
                <p className='text-[#344054] font-bold text-2xl lg:text-6xl mg:text-4xl xl:text-6xl'>Hei, {user.firstName}!</p>
            </div>
            <div className='mt-4 '>
                <p className='px-4 text-sm md:text-1xl lg:text-1xl xl:text-2xl text-[##344054]'>{bannerText}</p>
            </div>
        </div>
    );
};

export default UserDisplaySection;